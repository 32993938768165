import React, { useState } from 'react';
import { withNamespaces } from "react-i18next";
import i18next from 'i18next';
import Barcode from 'react-barcode';

const StickerSmall = React.forwardRef((props, ref) => {
    const [ratio, setRatio] = useState(window.innerWidth / 1920);

    window.addEventListener('resize', function() {
        setRatio(window.innerWidth / 1920);
    })

    return (
        <div className="small-sticker-item" ref={ref}>
            <div className="small-sticker-content">
                <div className="small-sticker-item-group-top">
                    <div className="small-sticker-item-group-top-col">
                        <div className="small-sticker-item-title">{i18next.t('Декларация №')}</div>
                        <div className="small-sticker-item-data">{props.printInfo['number']}</div>
                    </div>
                    <div className="small-sticker-item-group-top-col place">
                        <div className="small-sticker-item-title">{i18next.t('Место')}</div>
                        <div className="small-sticker-item-data">{`${props.printCardNumber}/${props.printInfo['parcelPlaces']}`}</div>
                    </div>
                </div>
                <div className="small-sticker-item-group">
                    <div className="small-sticker-item-title">
                        {i18next.t('Филия получателя')}
                    </div>
                    <div className="small-sticker-item-data">
                        {props.printInfo['afiiliate']}
                    </div>
                </div>
                <div className="small-sticker-item-group">
                    <div className="small-sticker-item-title">
                        {i18next.t('Город доставки')}
                    </div>
                    <div className="small-sticker-item-data">
                        {props.printInfo['deliveryCity']}
                    </div>
                </div>
                <div className="small-sticker-item-group">
                    <div className="small-sticker-item-title">
                        {i18next.t('Адресс')}
                    </div>
                    <div className="small-sticker-item-data">
                        {props.printInfo['address']}
                    </div>
                </div>
                <div className="small-sticker-item-group">
                    <div className="small-sticker-item-title">
                        {i18next.t('Получатель')}
                    </div>
                    <div className="small-sticker-item-data">
                        {props.printInfo['recipient']}
                    </div>
                </div>
            </div>
            <div className="small-sticker-barcode">
                {/*<Barcode value={`${props.printInfo['number']}/${props.printCardNumber}`} width={ratio < 0.84 ? 2 : 3} height={90 * ratio} font="Montserrat" fontSize={32 * ratio}/>*/}
                <Barcode value={`${props.printInfo['number']}/${props.printCardNumber}`} width={2} height={70} font="Montserrat" fontSize={14}/>
            </div>
        </div>
    );
});

export default withNamespaces()(StickerSmall);