import React, {useState, useRef, useEffect} from 'react'
import {Modal, Button, message, Tooltip} from 'antd'
import {useCookies} from 'react-cookie'
import Icon from './Icon'
import {withNamespaces} from 'react-i18next'
import i18next from 'i18next'
import PrintCardFocus from './PrintCardFocus'
import StickersList from './StickersList'
import {useReactToPrint} from 'react-to-print'
import ComponentToPrint from './ComponentToPrint'
import ModalPrintTip from './ModalPrintTip'

const ModalPrint = (props) => {
    const [cookies, setCookie] = useCookies()
    const placesAmount = props.printInfo['parcelPlaces']
    const allCheckedArr = Array(placesAmount).fill(true)
    const allUncheckedArr = Array(placesAmount).fill(false)
    const [sawTip, setSawTip] = useState(cookies.clientsawprinttip)

    const [printCardNumber, setPrintCardNumber] = useState(1)
    const [isActive, setIsActive] = useState(1)
    const [checked, setChecked] = useState(allCheckedArr)
    const [isPrintDisabled, setIsPrintDisabled] = useState(false)
    const [isTipModalVisible, setIsTipModalVisible] = useState(false)

    function isPrintCardNumberChange(num) {
        setPrintCardNumber(num)
    }

    function isActiveChange(index) {
        setIsActive(index)
    }

    function isCheckedChange(selected, index) {
        let newChecked = [...checked]
        newChecked[index] = selected
        setChecked(newChecked)
    }

    const handleOk = () => {
        props.onPrintModalVisibilityChange(false)
    }

    const select = () => {
        checked.indexOf(false) === -1 ? setChecked(allUncheckedArr) : setChecked(allCheckedArr)
    }

    const handleCancel = () => {
        props.onPrintModalVisibilityChange(false)
    }

    const success = () => {
        const text = i18next.t('Номер посылки скопирован в буфер обмена')
        message.success(text)
    }

    const copyParcelNumber = (str) => {
        let el = document.createElement('textarea')
        el.value = str
        el.setAttribute('readonly', '')
        el.style = { position: 'absolute', left: '-9999px' }
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
    }

    const getSelectAllBtnClass = () => {
        return placesAmount === 1 ? 'single' : ''
    }

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const showPrintTipModal = () => {
        setIsTipModalVisible(true)
    }

    useEffect(() => {
        setIsPrintDisabled(checked.indexOf(true) < 0)
    }, [checked])

    const onTipModalClose = () => {
        setIsTipModalVisible(false);
        handlePrint();

        fetch('https://highway.biz.ua/api/1c/confirmPrintTip', {
            method: 'POST',
            body: JSON.stringify({'id': cookies.clientdbid}),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(() => {
                setCookie('clientsawprinttip', 'true', { path: '/' });
                setSawTip('true')
            });
    }

    return (
        <Modal visible={props.isPrintModalVisible} onOk={handleOk} onCancel={handleCancel} centered
               closable={false} className={`table-modal print ${props.printType === 'a5' ? 'a5' : ''}`}>
            <div style={{ display: 'none' }}>
                {/*<div className="to-print-component">*/}
                <ComponentToPrint
                    printCardNumber={printCardNumber}
                    printInfo={props.printInfo}
                    printA5Info={props.printA5Info}
                    checked={checked}
                    ref={componentRef}
                    printType={props.printType}
                />
            </div>

            <div className="modal-print-wrapper">
                <div className="modal-print-focus">
                    <div className="parcel-number table-modal-head">
                        <div className="table-modal-title">{i18next.t('Декларация')} № &nbsp;</div>
                        <div className="table-modal-data">{props.printInfo['number']}</div>
                        <Tooltip title={i18next.t('Выбрать для печати все наклейки')}
                                 className={getSelectAllBtnClass()}>
                            <Button onClick={select}>
                                <Icon src="/images/select-all.svg"/>
                            </Button>
                        </Tooltip>
                        <Tooltip title={i18next.t('Скопировать номер посылки')}>
                            <Button onClick={() => {
                                copyParcelNumber(props.printInfo['number'])
                                success()
                            }}>
                                <Icon src="/images/copy.svg"/>
                            </Button>
                        </Tooltip>
                        <Tooltip title={i18next.t('Подготовить версию для печати')}
                                 overlayStyle={{ whiteSpace: 'nowrap', }}>
                            <Button onClick={() => sawTip === 'true' ? handlePrint() : showPrintTipModal()}
                                    disabled={isPrintDisabled}>
                                <Icon src="/images/print.svg"/>
                            </Button>
                        </Tooltip>
                    </div>
                    <PrintCardFocus
                      printCardNumber={printCardNumber}
                      printInfo={props.printInfo}
                      printType={props.printType}
                      printA5Info={props.printA5Info}
                    />

                    <div className="close" onClick={() => props.onPrintModalVisibilityChange(false)}>
                        <Icon src="/images/close.svg"/>
                    </div>
                </div>
                <StickersList
                    stickersAmount={placesAmount}
                    onPrintCardNumberChange={isPrintCardNumberChange}
                    onActiveChange={isActiveChange}
                    active={isActive}
                    isChecked={checked}
                    onIsCheckedChange={isCheckedChange}
                    printType={props.printType}
                />
            </div>
            <ModalPrintTip
                isTipModalVisible={isTipModalVisible}
                onTipModalVisibilityChange={() => onTipModalClose()}
            />
        </Modal>
    )
}

export default withNamespaces()(ModalPrint)
