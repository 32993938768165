import React from 'react'
import { Modal } from 'antd';
import { withNamespaces } from "react-i18next";
import i18next from 'i18next';
import Icon from "./Icon";

const ModalPrintTip = (props) => {
    const handleOk = () => {
        props.onTipModalVisibilityChange();
    }

    const handleCancel = () => {
        props.onTipModalVisibilityChange();
    }

    return (
        <Modal
            centered
            visible={props.isTipModalVisible}
            onCancel={handleCancel}
            closeIcon={<Icon src="/images/close.svg" />}
            className="modal-print-tip"
            wrapClassName="modal-print-tip-wrapper"
        >
            <div className="container">
                <div className="top">
                    <div className="important">
                        <img src="/images/attention.svg" alt=""/>
                        <div>{i18next.t('Важно!')}</div>
                    </div>
                    <div className="top-text">{i18next.t('Для корректной печати необходимо отключить в настройках поля')}</div>
                </div>
                <div className="main-text">{i18next.t('Выберите правильный параметр, а именно:')}</div>
                <div className="main-text list">
                    <div>{i18next.t('В диалоговом окне "Печать"')}</div>
                    <img src="/images/arrow-right-orange.svg" alt=""/>
                    <div>{i18next.t('Дополнительные настройки')}</div>
                    <img src="/images/arrow-right-orange.svg" alt=""/>
                    <div className="no-pad">
                        <div>{i18next.t('Поля')}</div>
                        <div>
                            <img src="/images/no-padding.svg" alt=""/>
                            <div className="image-text">{i18next.t('Нет')}</div>
                        </div>
                    </div>
                </div>
                <button onClick={handleOk}>{i18next.t('Ок, понятно')}</button>
            </div>
        </Modal>
    )
}

export default withNamespaces()(ModalPrintTip)