import React from "react";

const Phone = (props) => {

    function formatPhone(phone) {
        return phone.split(' ').join('');
    }

    return (
        <div className="header-contacts-link">
            <img src="/images/phone-white.svg" alt=""/>
            <a href={`tel:${formatPhone(props.phone)}`}>{props.phone}</a>
        </div>
    );
};

export default Phone;