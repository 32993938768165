import React from 'react';
import { Modal, Button, message, Tooltip } from 'antd';
import Icon from "./Icon";
import { withNamespaces } from "react-i18next";
import i18next from 'i18next';

const ModalParcelInfo = (props) => {
    const print = () => {
        window.print();
    }

    const handleOk = () => {
        props.onModalVisibilityChange(false);
    }

    const handleCancel = () => {
        props.onModalVisibilityChange(false);
    }

    const success = () => {
        const text = i18next.t("Номер посылки скопирован в буфер обмена");
        message.success(text);
    }

    const copyParcelNumber = (str) => {
        let el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    const translateStatus = (status) => {
        return i18next.t(status);
    }

    const renderDateFact = () => {
        return (
            <div className="parcel-delivery-date">
                <div className="table-modal-title">{i18next.t("Фактическая дата доставки")}</div>
                <div className="table-modal-data">{props.parcelInfo['dateFact']}</div>
            </div>
        )
    }

    const getWeight = () => {
        if (props.parcelInfo['weight'] >= props.parcelInfo['volumeWeight']) {
            return `${props.parcelInfo['weight']} кг (${i18next.t('фактический')})`;
        }
        return `${props.parcelInfo['volumeWeight']} кг (${i18next.t('объемный')})`;
    }

    const getDate = () => {
        // return props.parcelInfo['dateAlt'] === '01.01.0001' ? props.parcelInfo['date'] : props.parcelInfo['dateAlt'];
        return props.parcelInfo['date'];
    }

    return (
        <Modal visible={props.isModalVisible} onOk={handleOk} onCancel={handleCancel} centered closeIcon={<Icon src="/images/close.svg" />} className="table-modal info-modal">
            <div className="parcel-number table-modal-head">
                <div className="table-modal-title">{i18next.t("Декларация")} № &nbsp;</div>
                <div className="table-modal-data">{props.parcelInfo['number']}</div>
                <Tooltip title={i18next.t("Скопировать номер посылки")}>
                    <Button onClick={() => { copyParcelNumber(props.parcelInfo['number']); success(); }} >
                        <Icon src="/images/copy.svg" />
                    </Button>
                </Tooltip>
                <Tooltip title={i18next.t("Подготовить версию для печати")} overlayStyle={{whiteSpace: 'nowrap',}}>
                    <Button onClick={print}>
                        <Icon src="/images/print.svg" />
                    </Button>
                </Tooltip>
            </div>
            <div className="table-modal-body">
                <div className="parcel-delivery-date">
                    <div className="table-modal-title">{i18next.t("Дата отправки")}</div>
                    <div className="table-modal-data">{props.parcelInfo['parcelDate']}</div>
                </div>
                <div className="parcel-delivery-date">
                    <div className="table-modal-title">{i18next.t("Дата доставки")}</div>
                    <div className="table-modal-data">{getDate()}</div>
                </div>
                {props.parcelInfo['status'].indexOf('Доставлен') >= 0 ? renderDateFact() : false}
                <div className="modal-status">
                    <div className="table-modal-title">{i18next.t("Статус документа, посылка")}</div>
                    <div className={`table-modal-data parcel-status ${props.parcelInfo['statusName']}`}>{translateStatus(props.parcelInfo['status'])}</div>
                </div>
                <div className="parcel-delivery-date">
                    <div className="table-modal-title">{i18next.t("Получатель")}</div>
                    <div className="table-modal-data">{props.parcelInfo['recipient']}</div>
                </div>
                <div className="parcel-delivery-date">
                    <div className="table-modal-title">{i18next.t("Телефон получателя")}</div>
                    <div className="table-modal-data">{props.parcelInfo['phone']}</div>
                </div>
                <div className="parcel-delivery-date">
                    <div className="table-modal-title">{i18next.t("Вес")}</div>
                    <div className="table-modal-data">{getWeight()}</div>
                </div>
                <div className="parcel-delivery-date">
                    <div className="table-modal-title">{i18next.t("Стоимость доставки с НДС")}</div>
                    <div className="table-modal-data">{props.parcelInfo['costOfDelivery']} грн.</div>
                </div>
                <div className="parcel-delivery-date">
                    <div className="table-modal-title">{i18next.t("Оценочная стоимость")}</div>
                    <div className="table-modal-data">{props.parcelInfo['price']} грн.</div>
                </div>
            </div>
        </Modal>
    );
};

export default withNamespaces()(ModalParcelInfo);
