import ReactExport from "react-export-excel";
import { withNamespaces } from "react-i18next";
import i18next from 'i18next';

const Download = (props) => {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
        <ExcelFile element={<button className="dashboard-export-button">
                                <img src="/images/excel.svg" alt=""/>
                                <span>{i18next.t('Экспортировать')}</span>
                            </button>}>
            <ExcelSheet data={props.parcels} name="Parcels">
                <ExcelColumn label={i18next.t("Номер декларации")} value="number" />
                <ExcelColumn label={i18next.t("Дата отправки")} value="sendingDate" />
                <ExcelColumn label={i18next.t("Дата доставки")} value="deliveryDate" />
                <ExcelColumn label={i18next.t("Адрес доставки")} value="adress" />
                <ExcelColumn label={i18next.t("Статус")} value="status" />
                <ExcelColumn label={i18next.t("Получатель")} value="receiver" />
                <ExcelColumn label={i18next.t("Телефон получателя")} value="phone" />
                <ExcelColumn label={i18next.t("Вес")} value="weight"/>
                <ExcelColumn label={i18next.t("Стоимость доставки с НДС")} value="deliveryCost" />
                <ExcelColumn label={i18next.t("Оценочная стоимость")} value="price" />
            </ExcelSheet>
        </ExcelFile>
    );
}

export default withNamespaces()(Download);