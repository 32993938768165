import React, { useState } from 'react';
import { withNamespaces } from "react-i18next";
import i18next from 'i18next';
import { useCookies } from 'react-cookie';
import ModalParcelInfo from './ModalParcelInfo';
import ModalPrint from './ModalPrint';

const Tablerow = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);
    const [cookies] = useCookies();

    const isModalVisibilityChange = (newVisibility) => {
        setIsModalVisible(newVisibility);
    }

    const isPrintModalVisibilityChange = (newVisibility) => {
        setIsPrintModalVisible(newVisibility);
    }

    const showModal = () => {
        setIsModalVisible(true);
    }

    const showPrintModal = () => {
        setIsPrintModalVisible(true);
    }

    const translateStatus = (status) => {
        return i18next.t(status);
    }

    const langClass = () => {
        return cookies.lang === 'ru' ? 'status-ru' : '';
    }

    const getDate = () => {
        // return props.dateAlt === '01.01.0001' ? props.date : props.dateAlt;
        return props.date;
    }

    const parcelInfo = {
        'dateFact': props.dateFact,
        'weight': props.weight,
        'volumeWeight': props.volumeWeight,
        'number': props.number,
        'parcelDate': props.parcelDate,
        'date': props.date,
        // 'dateAlt': props.dateAlt,
        'status': props.status,
        'statusName': props.statusName,
        'recipient': props.recipient,
        'phone': props.phone,
        'costOfDelivery': props.costOfDelivery,
        'price': props.price
    }

    const printInfo = {
        'afiiliate': props.afiiliate,
        'district': props.district,
        'number': props.number,
        'parcelDate': props.parcelDate,
        'recipient': props.recipient,
        'sender': props.sender,
        'senderAddress': props.senderAddress,
        'address': props.address,
        'senderPhone': props.senderPhone,
        'phone': props.phone,
        'parcelPlaces': props.parcelPlaces,
        'weight': props.weight,
        'volume': props.volume,
        'type': props.type,
        'payer': props.payer,
        'paymentType': props.paymentType,
        'costOfDelivery': props.costOfDelivery,
        'price': props.price,
        'additionalServices': props.additionalServices,
        'date': props.date,
        // 'dateAlt': props.dateAlt,
        'deliveryCity': props.deliveryCity,
        // 'companyMistake': props.companyMistake,
        'other': props.other
    }

    const printA5Info = {
        ...printInfo,
        'controlWeight': props.controlWeight,
        'senderCity': props.senderCity,
        'transit': props.transit,
        'packingType': props.packingType,
        'accompanyingDescription': props.accompanyingDescription,
        'highwayRepresentative': props.highwayRepresentative,
        'cashOnDelivery': props.cashOnDelivery,
        'supermarket': props.supermarket,
        'saturday': props.saturday,
        'floorDelivery': props.floorDelivery,
        'floor': props.floor,
        'palletReturn': props.palletReturn,
        'palletAmount': props.palletAmount,
        'docsBack': props.docsBack,
        'cpBack': props.cpBack,
        'representativeDate': props.representativeDate,
        'representativePosition': props.representativePosition,
        'representativeName': props.representativeName,
        'representativePassport': props.representativePassport
    }

    return (
        <div className="dashboard-table-row">
            <div className="parcel-number">
                {props.number}
            </div>
            <div className="parcel-delivery-date">
                {props.parcelDate}
            </div>
            <div className="parcel-delivery-date planned">
                {getDate()}
            </div>
            <div className="parcel-delivery-address">
                {props.address}
            </div>
            <div className="parcel-recipient">
                {props.recipient}
            </div>
            <div className={`parcel-status ${props.statusName} ${langClass()}`}>
                {translateStatus(props.status)}
            </div>
            <div className="parcel-print-declaration">
                <button onClick={showPrintModal}>
                    <img src={props.printType === 'termo' ? '/images/print100.svg' : '/images/printA5.svg'} alt=""/>
                </button>
            </div>
            <div className="parcel-more-info-button">
                <button onClick={showModal}><img src="/images/more.svg" alt=""/></button>
            </div>

            <ModalPrint
                onPrintModalVisibilityChange={isPrintModalVisibilityChange}
                printInfo={printInfo}
                printA5Info={printA5Info}
                isPrintModalVisible={isPrintModalVisible}
                printType={props.printType}
            />
            <ModalParcelInfo
                onModalVisibilityChange={isModalVisibilityChange}
                parcelInfo={parcelInfo}
                isModalVisible={isModalVisible}
            />
        </div>
    );
};

export default withNamespaces()(Tablerow);
