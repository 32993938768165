import React from "react";
import {Radio, Tooltip} from 'antd';
import {withNamespaces} from "react-i18next";
import i18next from 'i18next';

const Filter = (props) => {

    function filterChange(val) {
        props.onFilterChange(val.target.value);
    }

    return (
        <Radio.Group defaultValue="All" buttonStyle="solid" onChange={filterChange}>
            <Radio.Button value="All">{i18next.t("Все")}</Radio.Button>
            <Tooltip placement="bottom" title={i18next.t("Ожидается груз на склад перевозчика")} color="#76859C">
                <Radio.Button value="InWork">{i18next.t("В работе")}</Radio.Button>
            </Tooltip>
            <Tooltip placement="bottom" title={i18next.t("Груз находится в пути по адресу назначения")} color="#76859C">
                <Radio.Button value="OnTheRoad">{i18next.t("В пути")}</Radio.Button>
            </Tooltip>
            <Tooltip placement="bottom" title={i18next.t("Груз доставлен по адресу назначения")} color="#76859C">
                <Radio.Button value="Delivered">{i18next.t("Доставлено")}</Radio.Button>
            </Tooltip>
        </Radio.Group>
    )
}

export default withNamespaces()(Filter);