import React from 'react';
import { withNamespaces } from "react-i18next";
import Icon from './Icon';
import { Checkbox } from 'antd';

const StickerItem = (props) => {
    const changePrintCardFocusNumber = () => {
        props.onPrintCardNumberChange(props.num);
        props.onActiveChange(props.num);
    }

    const isActive = () => {
        return props.active === props.num ? 'active' : '';
    }

    const onChange = () => {
        props.isChecked ? props.onIsCheckedChange(false, props.index) : props.onIsCheckedChange(true, props.index);
    }

    return (
        <div className={`modal-print-sticker-small-item ${isActive()} ${props.isMain ? 'main-a5-sticker' : ''}`}>
            <div className="modal-print-sticker-small-num">{props.num}</div>
            <div className="modal-print-sticker-small-item-img" onClick={changePrintCardFocusNumber}>
                {props.num === 1 && props.isMain
                    ? <Icon src="/images/stickerA5.jpg" />
                    : props.num === 1
                        ? <Icon src="/images/sticker-lg.jpg" />
                        : <Icon src="/images/sticker.jpg" />
                }
            </div>
            <Checkbox
                onChange={onChange}
                className="modal-print-sticker-small-checkbox"
                checked={props.isChecked}
            />
        </div>
    );
};

export default withNamespaces()(StickerItem);
