import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, Select, message, Upload } from 'antd';
import { withNamespaces } from "react-i18next";
import {useCookies} from "react-cookie";
import i18next from 'i18next';
import reqwest from 'reqwest';

const NotificationsAdmin = () => {
    const [cookies] = useCookies([]);
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const { Option } = Select;
    const [usersList, setUsersList] = useState([]);
    const users = [];

    const success = () => {
        let text = i18next.t('Сообщение успешно отправлено');
        message.success(text);
    };

    const error = (err) => {
        message.error(err);
    }

    useEffect(() => {
        fetch('https://highway.biz.ua/api/1c/getRegisteredUsers', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(json => {
                for (let i = 0; i < json[0].length; i++) {
                    users.push({
                        id: json[0][i]['client_id'],
                        name: `${json[1][i]['first_name']} ${json[2][i]['last_name']}`
                    })
                }
                setUsersList(users);
            });
    },[]);

    const onFinish = (values) => {
        fetch('https://highway.biz.ua/api/1c/sendMessage', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(json => {
                if (json.error) {
                    error(json.error);
                } else {
                    success();
                    form.resetFields();
                    return json.id;
                }
            })
            .then(id => {
                if (fileList.length > 0) {
                    handleUpload(id);
                }
            })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    function handleChange(value) {
        console.log(`selected ${value}`);
    }

    const monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
        "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
    ];

    const getDate = () => {
        let date, month;
        let newDate = new Date();
        newDate.getDate() < 10 ? date = `0${newDate.getDate()}` : date = newDate.getDate();
        month = newDate.getMonth();
        let year = newDate.getFullYear();
        return `${monthNames[month]} ${date}, ${year}`;
    }

    const getAuthor = () => {
        return `${cookies.clientname} ${cookies.clientlastname}`;
    }

    const getAvatar = () => {
        return cookies.clientava;
    }

    const [fileList, setFileList] = useState([]);

    const handleUpload = (id) => {
        const formData = new FormData();
        formData.append('id', id);

        fileList.forEach(file => {
            formData.append('files', file);
        });

        reqwest({
            url: 'https://highway.biz.ua/api/1c/sendAttachmentMessage',
            method: 'post',
            processData: false,
            data: formData,
            success: () => {
                setFileList([]);
                message.success('upload successfully.');
            },
            error: () => {
                message.error('upload failed.');
            },
        });
    }

    const uploadingProps = {
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: file => {
            setFileList([...fileList, file])
            return false;
        },
        fileList,
    };

    return (
            <Col className="notifications-content" span={20}>
                <div className="dashboard-content-top">
                    <h1>{i18next.t("Отправка уведомлений")}</h1>
                </div>
                <Form
                    form={form}
                    className={'admin-send-message'}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    initialValues={{ date: getDate(), author: getAuthor(), avatar: getAvatar() }}
                >
                    <div className="notifications-content-title">{i18next.t('Выберите пользователя для отправки сообщения:')}</div>
                    <Form.Item
                        name="user"
                        rules={[
                            {
                                required: true,
                                message: i18next.t("Выберите пользователя"),
                            }
                        ]}
                    >
                        <Select onChange={handleChange} className="select-user" showSearch>
                            {usersList && usersList.length ? (
                                usersList.map((el, index) => (
                                    <Option key={index} value={el.id}>{`${el.id} - (${el.name})`}</Option>
                                ))
                            ) : null}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="message"
                        rules={[
                            {
                                required: true,
                                message: i18next.t("Введите сообщение"),
                            },
                        ]}
                    >
                        <TextArea placeholder={i18next.t("Сообщение")} className='admin-msg' />
                    </Form.Item>
                    {/*<Upload {...uploadSettings}>*/}

                    <Form.Item name="date" style={{ display: 'none' }}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name="author" style={{ display: 'none' }}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name="avatar" style={{ display: 'none' }}>
                        <Input/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className={'submit-button'}>
                            {i18next.t("Отправить")}
                        </Button>
                    </Form.Item>
                </Form>

                <Upload {...uploadingProps}>
                    <button className="messages-attach-file">
                        <span>{i18next.t('Прикрепить файл')}</span>
                    </button>
                </Upload>
            </Col>

    );
};

export default withNamespaces()(NotificationsAdmin);