import React from "react";
import {Form, Input, Button, Row, Col, message} from 'antd';
import {Link, useHistory} from "react-router-dom";
import Icon from "./Icon";
import { withNamespaces } from 'react-i18next'
import Languages from "./Languages";

const Register = ({t}) => {
    const history = useHistory();
    const [form] = Form.useForm();

    const success = (msg) => {
        message.success(msg);
    };

    const error = (err) => {
        message.error(err);
    }

    const givenName = localStorage.getItem('g_name') || '';
    const familyName = localStorage.getItem('g_last_name') || '';
    const googleEmail = localStorage.getItem('g_mail') || '';
    const googleAva = localStorage.getItem('g_avatar') || '';

    const onFinish = (values) => {
        fetch('https://highway.biz.ua/api/1c/register', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(json => {
                if (json.error) {
                    localStorage.getItem('lang') === 'ru' ? error(json.error) : error(json.error_uk);
                } else {
                    localStorage.getItem('lang') === 'ru' ? success(json.success) : success(json.success_uk);
                    localStorage.removeItem('g_name');
                    localStorage.removeItem('g_last_name');
                    localStorage.removeItem('g_mail');
                    history.push('/login');
                }
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="bg">
            <Row>
                <Col span={14} className='image-packs'>

                </Col>
                <Col span={10} className='df'>
                    <Languages/>
                    <div className="inner-block">
                        <a className="logo" href="/">
                            <img src={t('/images/logo.svg')} alt=""/>
                        </a>
                        <h1>{t("Добро пожаловать")}!</h1>
                        <div className="heading-description">
                            {t("Для дальнейшей работы в личном кабинете, заполните форму")}
                        </div>
                        <Form
                            form={form}
                            className={'register-input-block'}
                            name="basic"
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            initialValues={{
                                "firstname": givenName,
                                "lastname": familyName,
                                "email": googleEmail,
                                "ava": googleAva
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >

                            <Form.Item
                                name="firstname"
                                rules={[
                                    {
                                        required: true,
                                        message: t("Введите ваше имя"),
                                    },
                                ]}
                            >
                                <Input placeholder={t("Имя")} className={'login-input'} suffix={<Icon src='/images/user.svg' />} />
                            </Form.Item>

                            <Form.Item
                                name="lastname"
                                rules={[
                                    {
                                        required: true,
                                        message: t("Введите вашу фамилию"),
                                    },
                                ]}
                            >
                                <Input placeholder={t("Фамилия")} className={'login-input'} suffix={<Icon src='/images/user.svg' />} />
                            </Form.Item>

                            <Form.Item
                                name="id1c"
                                rules={[
                                    {
                                        required: true,
                                        message: t("Введите ID пользователя 1С"),
                                    },
                                ]}
                            >
                                <Input placeholder={t("ID пользователя 1С")} className='login-input' suffix={<Icon src='/images/user1c.svg' />} autoComplete="autocomplete_off_hack_xfr4!k"/>
                            </Form.Item>

                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: t("Введите ваш телефон"),
                                    },
                                ]}
                            >
                                <Input type="tel" placeholder="Телефон" className='login-input' suffix={<Icon src='/images/phone.svg' />} />
                            </Form.Item>

                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: t("Введите ваш e-mail"),
                                    },
                                ]}
                            >
                                <Input type="email" placeholder="E-mail" className='login-input' suffix={<Icon src='/images/mail.svg' />} />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[
                                    { required: true, message: t("Введите пароль") },
                                    { min: 8, message: t("Пароль должен состоять минимум из 8 символов") },
                                ]}
                            >
                                <Input.Password placeholder="Пароль" className={'login-input'} />
                            </Form.Item>

                            <Form.Item name="ava" style={{ display: 'none' }}>
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                wrapperCol={{
                                    offset: 0,
                                    span: 24,
                                }}
                            >
                                <Button type="primary" htmlType="submit" className='submit-button'>
                                    {t("Отправить")}
                                </Button>
                            </Form.Item>

                        </Form>

                        <div className="auth-links">
                            <Row>
                                <Col>
                                    <Link to="/login" className="register-link">
                                        {t("Вернуться на страницу входа")}
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default withNamespaces()(Register);