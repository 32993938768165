import React from 'react';
import StickerMain from './StickerMain';
import StickerSmall from './StickerSmall';
import StickerA5Main from "./StickerA5Main";

const ComponentToPrint = React.forwardRef((props, ref) => {
    const renderMain = () => {
        let result = [];
        if (props.checked[0] === true) {
            const stickerMain = props.printType === 'a5'
                ? <>
                    <StickerA5Main printInfo={props.printInfo} printA5Info={props.printA5Info} key={0} barWidth={0.78} barHeight={40} />
                    <StickerA5Main printInfo={props.printInfo} printA5Info={props.printA5Info} key={0} barWidth={0.78} barHeight={40} />
                </>
                : <StickerMain printInfo={props.printInfo} key={0} barWidth={1.5} barHeight={36} />;
            result.push(
                stickerMain,
                <div className="page-break" key={'0br'} />
            )
        }

        return result;
    }

    const renderSmall = () => {
        let result = [];
        for (let i = 1; i < props.checked.length; i++) {
            if (props.checked[i] === true) {
                result.push(
                    <StickerSmall printInfo={props.printInfo} printCardNumber={i + 1} key={i + 'br'} />,
                    <div className="page-break" />
                )
            }
        }

        return result;
    }

    return (
        <div ref={ref} className="to-print-component">
            {renderMain()}
            {renderSmall()}
        </div>
    )
});

export default ComponentToPrint;
