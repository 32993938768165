import React from "react";

const Mail = (props) => {

    return (
        <div className="header-contacts-link">
            <img src="/images/mail-white.svg" alt=""/>
            <a href={`mailto:${props.mail}`}>{props.mail}</a>
        </div>
    );
};

export default Mail;