import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Header from "./Header";
import Sidebar from "./Sidebar";
import Icon from "./Icon";
import { withNamespaces } from "react-i18next";
import {useCookies} from "react-cookie";
import i18next from 'i18next';

const Settings = (props) => {
    const [cookies, setCookie] = useCookies([]);

    const [loading, setLoading] = useState(false);

    let googleAvaUrl = '';
    if (localStorage.getItem('g_avatar')) {
        googleAvaUrl = localStorage.getItem('g_avatar');
    }
    const destroyGoogleAva = () => {
        localStorage.removeItem('g_avatar');
    }

    const [imageUrl, setImageUrl] = useState(cookies.clientava);
    const [uploadedImage, setUploadedImage] = useState(null);

    const success = () => {
        let text = i18next.t("Данные успешно обновлены");
        message.success(text);
    };

    const error = (err) => {
        message.error(err);
    }

    function uploadImageToServer(img, callback) {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = function () {
            const data = {
                avatar: reader.result,
                dbid: cookies.clientdbid
            };
            fetch('https://highway.biz.ua/api/1c/uploadImage', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json())
            .then(json => {
                setImageUrl(json);
                return json;
            })
            .then(url => {
                document.querySelector('.user-avatar').src = url;
                setCookie('clientava', url);
            });
        };
    }

    function beforeUpload(file) {
        const isJpg = file.type === 'image/jpeg';
        if (!isJpg) {
            const text = i18next.t("Выберите фото в формате JPG")
            message.error(text);
            return false;
        }
        const isLt1M = file.size / 1024 / 1024 < 1;
        if (!isLt1M) {
            const text2 = i18next.t("Выберите фото не больше 1MB");
            message.error(text2);
            return false;
        }

        if (!cookies.clientdbid || cookies.clientdbid === 'undefined') {
            const text3 = i18next.t("Авторизируйтесь для загрузки фото")
            message.error(text3);
            return false;
        }
        uploadImageToServer(file);
        return false;
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>{i18next.t("Загрузить аватар")}</div>
        </div>
    );

    const onFinish = (values) => {
        fetch('https://highway.biz.ua/api/1c/editProfile', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(json => {
                if (json.error) {
                    localStorage.getItem('lang') === 'ru' ? error(json.error) : error(json.error_uk);
                } else {
                    setCookie('clientname', json.first_name);
                    setCookie('clientlastname', json.last_name);
                    setCookie('clientmail', json.email);
                    setCookie('clientphone', json.phone_number);
                    setCookie('clientposition', json.position);
                    document.querySelector('.admin-panel-info .user-name').textContent = `${cookies.clientname} ${cookies.clientlastname[0]}.`;
                    document.querySelector('.admin-panel-info .user-position').textContent = cookies.clientposition;
                    success();
                }
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [newPassValue, setNewPassValue] = useState('');
    const [newPassRepeatValue, setNewPassRepeatValue] = useState('');

    const onNewPassChange = (e) => {
        setNewPassValue(e.target.value);
    }

    const onNewPassRepeatChange = (e) => {
        setNewPassRepeatValue(e.target.value);
    }

    useEffect(() => {
        setSubmitDisabled(newPassValue !== newPassRepeatValue)
    }, [newPassValue, newPassRepeatValue]);

    const userPos = cookies.clientposition;

    return (
        <div className="settings-page">
            <Header onIsAuthChange={props.onIsAuthChange} onMsgChange={props.onMsgChange} messages={props.messages} />
            <Row className="cabinet-content">
                <Col span={4} >
                    <Sidebar />
                </Col>
                <Col className="settings-content" span={20}>
                    <div className="dashboard-content-top">
                        <h1>{i18next.t("Редактировать профиль")}</h1>
                    </div>
                    <div className="settings-form">
                        <Form
                            name="basic"
                            initialValues={{
                                remember: true,
                                "firstname": cookies.clientname,
                                "lastname": cookies.clientlastname,
                                "email": cookies.clientmail,
                                "phone": cookies.clientphone,
                                "position": userPos,
                                "dbid": cookies.clientdbid
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item label={i18next.t("Личная информация")} className="settings-form-general">
                                <Form.Item
                                    label={i18next.t("Имя")}
                                    name="firstname"
                                    rules={[{ required: true, message: i18next.t("Введите ваше имя") }]}
                                >
                                    <Input
                                        suffix={<Icon src='/images/user.svg' />}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={i18next.t("Фамилия")}
                                    name="lastname"
                                    rules={[{ required: true, message: i18next.t("Введите вашу фамилию") }]}
                                >
                                    <Input
                                        suffix={<Icon src='/images/user.svg' />}
                                    />
                                </Form.Item>
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                                    enctype="multipart/form-data"
                                >
                                    {imageUrl ? <img src={googleAvaUrl ? googleAvaUrl : imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                    {destroyGoogleAva()}
                                </Upload>
                            </Form.Item>
                            <Form.Item className="settings-form-contacts">
                                <Form.Item
                                    label="E-mail"
                                    name="email"
                                    rules={[{ required: true, message: i18next.t("Введите ваш e-mail") }]}
                                >
                                    <Input
                                        suffix={<Icon src='/images/mail.svg' />}
                                        autoComplete="off"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Телефон"
                                    name="phone"
                                    rules={[{ required: true, message: i18next.t("Введите ваш телефон") }]}
                                >
                                    <Input
                                        suffix={<Icon src='/images/phone.svg' />}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={i18next.t("Должность")}
                                    name="position"
                                    rules={[{ message: i18next.t("Введите вашу должность") }]}
                                >
                                    <Input suffix={<Icon src='/images/user.svg' />} />

                                </Form.Item>
                            </Form.Item>
                            <Form.Item label={i18next.t("Смена пароля")} className="settings-form-passwords">
                                <Form.Item
                                    label={i18next.t("Новый пароль")}
                                    name="newPass"
                                    rules={[
                                        { message: i18next.t("Введите ваш новый пароль") },
                                        { min: 8, message: i18next.t("Пароль должен состоять минимум из 8 символов") },
                                    ]}
                                >
                                    <Input.Password autoComplete="new-password" onChange={onNewPassChange} />
                                </Form.Item>
                                <Form.Item
                                    label="Повтор пароля"
                                    name="newPassRepeat"
                                    rules={[
                                        { message: i18next.t("Введите ваш пароль ещё раз") },
                                        { min: 8, message: i18next.t("Пароль должен состоять минимум из 8 символов") },
                                    ]}
                                >
                                    <Input.Password autoComplete="off"  onChange={onNewPassRepeatChange} />
                                </Form.Item>
                                <Form.Item name="dbid" style={{ display: 'none' }}>
                                    <Input/>
                                </Form.Item>
                            </Form.Item>
                            <Form.Item className="settings-submit">
                                <Button type="primary" htmlType="submit" className='submit-button' disabled={submitDisabled} >
                                    {i18next.t("Сохранить")}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default withNamespaces()(Settings);