import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import i18next from "i18next";

const Languages = () => {
    const [lang, setLang] = useState(localStorage.getItem('lang') || 'ru');

    const changeLang = (e) => {
        setLang(e.target.name);
        localStorage.setItem('lang', e.target.name);
        i18next.changeLanguage(e.target.name);
    }

    return (
        <div className="lang-select df">
            <button onClick={changeLang} name="uk" className={lang === 'uk' ? 'active' : 'inactive'}>Укр</button>
            <button onClick={changeLang} name="ru" className={lang === 'ru' ? 'active' : 'inactive'}>Рус</button>
        </div>
    );
};

export default Languages;
