import React from "react";
import { Pagination } from 'antd';

const Tablepagination = (props) => {

    function pageChange(page) {
        props.onChange(page);
    }

    return (
        <Pagination
            defaultCurrent={1}
            onChange={pageChange}
            showSizeChanger={false}
            pageSize={7}
            defaultPageSize={7}
            total={props.total}
            showTitle={false}
        />
    );
};

export default Tablepagination;