import React, {useEffect, useState} from "react";
import {Row, Col} from 'antd';
import Logo from "./Logo";
import Mail from "./Mail";
import Phone from "./Phone";
import {Link} from "react-router-dom";
import Languages from "./Languages";
import { withNamespaces } from "react-i18next";
import { useCookies } from 'react-cookie';
import i18next from 'i18next';

const Header = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies([]);

    const logout = () => {
        props.onIsAuthChange(false);
        removeCookie('authtoken');
        removeCookie('tokenexpires');
        removeCookie('clientid');
        removeCookie('clientname');
        removeCookie('clientlastname');
        removeCookie('clientmail');
        removeCookie('clientphone');
        removeCookie('clientposition');
        removeCookie('clientisadmin');
        removeCookie('clientdbid');
        removeCookie('clientava');
        removeCookie('clientunreadmsg');
        removeCookie('clientsawprinttip');
        setCookie('clientisauthed', false, { path: '/' });
        return false;
    }

    if (cookies.clientisadmin !== 'admin') {
        (function getUnreadMessagesAmount() {
            fetch('https://highway.biz.ua/api/1c/hasUnreadMessages', {
                method: 'POST',
                body: JSON.stringify({
                    user: cookies.clientdbid
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(json => {
                    props.onMsgChange(+json.msg)
                });
        })();
    }

    const showUnreadMsg = () => {
        return props.messages > 0 && cookies.clientisadmin !== 'admin' ? <div className="unread-messages">{props.messages}</div> : null;
    }

    return (
        <header className="header">
            <Row>
                <Col span={4} className="header-logo">
                    <Logo/>
                </Col>
                <Col className="header-contacts df">
                    <Mail mail="msp_hw@highway.biz.ua" />
                    <Phone phone="+38 067 422 28 88" />
                    <Phone phone="+38 050 391 95 80" />
                </Col>
                <Col className="header-buttons df">
                    <a href={ localStorage.getItem('lang') === "ru" ? "https://highway.biz.ua/ru" : "https://highway.biz.ua/" }>{i18next.t('Главная')}</a>
                    <Languages/>
                    <Link to="/notifications" className={`notific-link `}>
                        <img src="/images/notification.svg" alt=""/>
                        {showUnreadMsg()}
                    </Link>

                    <Link
                        to='/login'
                        className="logout-link"
                        onClick={logout}
                    >
                        <img src="/images/logout.svg" alt=""/>
                    </Link>
                </Col>
            </Row>
        </header>
    );
};

export default withNamespaces()(Header);
