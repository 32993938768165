import { useEffect } from 'react';

export default function useOutsideClick(ref, actionToPerform) {
    return useEffect(() => {

        function handleClickOutside(e) {
            if (ref.current && ref.current.contains && !ref.current.contains(e.target)) {
                actionToPerform()
            }
        }

        window.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('touchend', handleClickOutside);

        return () => {
            window.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('touchend', handleClickOutside);
        }
    }, [ref])
}
