import React from "react";
import { withNamespaces } from "react-i18next";

const Logo = ({t}) => {

    return (
        <a href={ localStorage.getItem('lang') === "ru" ? "https://highway.biz.ua/ru" : "https://highway.biz.ua/" } >
            <img src={t("/images/logo.svg")} alt=""/>
        </a>
    );
};

export default withNamespaces()(Logo);
