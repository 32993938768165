import React from "react";
import Icon from "./Icon";
import { withNamespaces } from "react-i18next";
import i18next from 'i18next';

const Message = (props) => {
    const markAsRead = (e) => {
        if (!e.target.closest('.message').classList.contains('unread')) return;

        e.target.closest('.message').classList.remove('unread');

        props.onMsgChange(props.messages - 1);

        fetch('https://highway.biz.ua/api/1c/readMessage', {
            method: 'POST',
            body: JSON.stringify({'id': props.id}),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(json => {
                console.log(json);
            });
    }

    const getAttachment = () => {
        if (props.attachment.length > 0) {
            const fileName = props.attachment.split('/')[1];
            return (<a href={`https://highway.biz.ua/${props.attachment}`} className="message-attachment" title={i18next.t("Скачать вложенный файл")} download>
                        <Icon src="/images/attach.svg" />
                        <span>{fileName}</span>
                    </a>)
        }

        return <a className="message-attachment"/>
    }

    return (
        <div className={`message ${props.unread}`} title={i18next.t("Позначить как прочитанное")} onClick={markAsRead}>
            <div className="message-avatar" >
                <Icon src={props.avatar} />
            </div>
            <div className="message-content">
                <div className="message-author">{props.author}</div>
                <div className="message-text">{props.text}</div>
            </div>
            <div className="message-right-wrapper">
                <div className="message-date">{props.date}</div>
                {getAttachment()}

            </div>
        </div>
    );
}

export default withNamespaces()(Message);