import React from 'react';
import { withNamespaces } from "react-i18next";
import StickerItem from './StickerItem';

const StickersList = (props) => {
    let stickers = [];
    let empty = 'empty';

    if (props.stickersAmount > 1) {
        for (let i = 0; i < props.stickersAmount; i++) {
            stickers.push(
                <StickerItem
                    num={i + 1}
                    key={i}
                    onPrintCardNumberChange={props.onPrintCardNumberChange}
                    onActiveChange={props.onActiveChange}
                    active={props.active}
                    isChecked={props.isChecked[i]}
                    onIsCheckedChange={props.onIsCheckedChange}
                    index={i}
                    isMain={props.printType === 'a5' && i === 0}
                />
            )
        }

        empty = '';
    }

    return (
        <div className={`modal-print-stickers-list ${empty} ${props.printType === 'a5' ? 'a5' : ''}`}>
            {stickers}
        </div>
    );
};

export default withNamespaces()(StickersList);
