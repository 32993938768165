import React, {useRef, useState} from "react";
import { Doughnut } from 'react-chartjs-2';

const StatsChart = (props) => {
    const [ratio, setRatio] = useState(window.innerWidth / 1920);

    window.addEventListener('resize', function() {
        setRatio(window.innerWidth / 1920);
    })

    const data = {
        labels: [],
        datasets: [
            {
                data: [
                    props.percent,
                    100 - props.percent
                ],
                backgroundColor: [
                    props.color,
                    'transparent',
                ],
                borderColor: '#fff',
                borderWidth: [2 * ratio, 0],
                borderRadius: [20 * ratio, 0],
                hoverBorderColor: '#fff',
                hoverBackgroundColor: [
                    props.color,
                    'transparent',
                ],
                offset: 0,
                // offset: [5, 5],
                cutout: 100 * ratio,
                rotation: 0
            }
        ],
    };

    const options = {
        tooltips: {enabled: false},
        hover: {mode: null},
        events: [],
        radius: '100%',
        elements: {
            arc: {
                spacing: 0
            }
        },
    }

    return (
        <div className="stats-item-chart-wrapper">
            <div className="stats-item-chart">
                <div className="stats-charts-canvas-wrapper">
                    <Doughnut data={data} options={options} width={264} height={264}/>
                </div>
                <div className="stats-item-chart-percent">{props.percent}%</div>
                <div className="stats-item-chart-line"></div>
            </div>
            <div className="stats-item-chart-text">{props.text}</div>
        </div>
    );
};

export default StatsChart;