import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import { Menu } from 'antd';
import Icon from "./Icon";
import { withNamespaces } from "react-i18next";
import { useCookies } from 'react-cookie';

const Navigation = ({t}) => {
    const [cookies, setCookie] = useCookies([]);

    const url = window.location.href;
    const [current, setCurrent] = useState('');

    useEffect(() => {
        if (url.indexOf('dashboard') >= 0) {
            setCurrent('dashboard');
        } else if (url.indexOf('notifications') >= 0) {
            setCurrent('notifications');
        } else if (url.indexOf('statistics') >= 0) {
            setCurrent('statistics');
        }
    }, [current])

    return(
        <Menu
            defaultSelectedKeys={[current]}
            selectedKeys={[current]}
            mode="inline"
            theme="light"
            className="admin-panel-links"
            style={
                {
                    borderRight: "none",
                }
            }
        >
            <Menu.Item key="dashboard">
                <Link to="/dashboard">
                    <div className="icon-wrapper">
                        <Icon src="/images/list.svg" />
                    </div>
                    <span>{t("История отправления")}</span>
                </Link>
            </Menu.Item>
            {/*<Menu.Item key="notifications">*/}
            {/*    <Link to="/notifications">*/}
            {/*        <div className="icon-wrapper">*/}
            {/*            <Icon src="/images/envelope.svg" />*/}
            {/*        </div>*/}
            {/*        <span>{t("Сообщения")}</span>*/}
            {/*        {showUnreadMsg()}*/}
            {/*    </Link>*/}
            {/*</Menu.Item>*/}
            <Menu.Item key="statistics" className="stats-link">
                <Link to="/statistics">
                    <div className="icon-wrapper">
                        <Icon src="/images/stats.svg" />
                    </div>
                    <span>{t("Статистика отправлений")}</span>
                </Link>
            </Menu.Item>
        </Menu>
    )
}

export default withNamespaces()(Navigation);