import React from "react";

const StatsItem = (props) => {
    let c;
    props.className ? c = props.className : c = '';

    return (
        <div className="stats-item">
            <div className={`stats-item-heading ${c}`}>{props.text}</div>
            <div className="stats-item-circle" style={{borderColor: props.color}}>
                {props.number}
            </div>
        </div>
    );
};

export default StatsItem;