export function getCurrentDate() {

    let date, month;
    let newDate = new Date();
    newDate.getDate() < 10 ? date = `0${newDate.getDate()}` : date = newDate.getDate();
    newDate.getMonth() + 1 < 10 ? month = `0${newDate.getMonth() + 1}` : month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${month}${date}`;
}

export function getPreviousDate() {

    let newDate = new Date();
    let prevDate = new Date().setDate(newDate.getDate() - 31);
    let prevDateFormatted = new Date(prevDate);
    let date = prevDateFormatted.getDate();
    let month = prevDateFormatted.getMonth() + 1;
    let year = prevDateFormatted.getFullYear();

    return `${year}${month < 10 ? `0${month}` : `${month}`}${date < 10 ? `0${date}` : `${date}`}`;
}

export function isTokenValid(id, token) {
    return (
        fetch('https://highway.biz.ua/api/1c/getParcelsList', {
            method: 'POST',
            body: JSON.stringify({
                "DateFrom": "20210705",
                "DateTo": "20210704",
                "Index": "1",
                "PageSize": "1",
                "ClientID": id,
                "SortMode": "0",
                "Filter": "All",
                "x-auth-token": token,
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => res.json())
            .then(data => {
                console.log(data.Status === 'OK');
                return data.Status === 'OK';
            })
    )
}
