import React, {useEffect, useState} from "react";
import {Col} from 'antd';
import Message from "./Message";
import { withNamespaces } from "react-i18next";
import {useCookies} from "react-cookie";
import i18next from 'i18next';

const NotificationsUser = (props) => {
    const [cookies, setCookie] = useCookies([]);
    const [messagesList, setMessagesList] = useState([]);
    const list = [];

    useEffect(() => {
        fetch('https://highway.biz.ua/api/1c/getMessages', {
            method: 'POST',
            body: JSON.stringify({
                user: cookies.clientdbid,
                id: cookies.clientid
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(json => {
                for (let el of json.msg) {
                    list.push(el);
                }
                setMessagesList(list.reverse());
            });
    },[]);

    function dateTranslation(date) {
        let month = i18next.t(date.split(' ')[0]);
        let day = date.split(' ')[1];
        let year = date.split(' ')[2];
        return [month, day, year].join(' ');
    }

    return (
            <Col className="notifications-content" span={20}>
                <div className="dashboard-content-top">
                    <h1>{i18next.t("Уведомления")}</h1>
                </div>
                <div className="messages-list">
                    {messagesList && messagesList.length ? (
                        messagesList.map(el => (
                            <Message
                                avatar={el.avatar}
                                author={el.author}
                                text={el.message}
                                attachment={el.file}
                                date={dateTranslation(el.date)}
                                unread={el.flag}
                                id={el.id}
                                key={el.id}
                                onMsgChange={props.onMsgChange}
                                messages={props.messages}
                            />
                        ))
                    ) : null}
                </div>
            </Col>

    );
};

export default withNamespaces()(NotificationsUser);