import React from 'react';
import {withNamespaces} from "react-i18next";
import Barcode from "react-barcode";
import i18next from "i18next";

const StickerA5Main = React.forwardRef((props, ref) => {
    return (
        <div className="print-a5-container">
            <div className="print-a5-table-wrapper" ref={ref}>
                <div className="print-a5-col-left">
                    <div className="print-a5-col-left-main">
                        <div className="print-a5-col-left-main-logo">
                            <img src="/images/a5-logo.svg" alt=""/>
                        </div>
                        <div className="print-a5-col-left-main-wrapper">
                            <div className="print-a5-col-left-main-wrapper-row">
                                <div className="print-a5-col-left-main-wrapper-row-col">
                                    <div className="print-a5-col-left-main-wrapper-label">Накладна</div>
                                    <div
                                        className="print-a5-col-left-main-wrapper-number">{props.printA5Info.number}</div>
                                </div>
                                <div
                                    className="print-a5-col-left-main-wrapper-parcel-district">{props.printA5Info.transit}</div>
                            </div>
                        </div>
                        <Barcode
                            value={props.printA5Info.number + '/1'}
                            width={props.barWidth ? props.barWidth : 1}
                            height={props.barHeight ? props.barHeight : 53}
                            font="Montserrat" displayValue={false}
                        />
                        <div className="print-a5-col-left-main-parcel-date">{props.printA5Info.parcelDate}</div>
                    </div>
                    <div className="print-a5-col-left-recipient-branch">
                        <div className="print-a5-col-left-recipient-branch-row">
                            <div className="print-a5-col-left-recipient-branch-label">Філія отримувача</div>
                            <div
                                className="print-a5-col-left-recipient-branch-number">{props.printA5Info.district}</div>
                        </div>
                        <div className="print-a5-col-left-recipient-branch-name">{props.printA5Info.afiiliate}</div>
                    </div>
                    <div className="print-a5-col-left-additional">
                        <div className="print-a5-label">Додаткова інформація</div>
                        <div className="print-a5-text-block">{props.printA5Info.other}</div>
                    </div>
                    <div className="print-a5-col-left-hgw-representative">
                        <div className="print-a5-label">Представник ТОВ "Хайвэй"</div>
                        <div className="print-a5-text-block">{props.printA5Info.highwayRepresentative}</div>
                    </div>
                    <div className="print-a5-col-left-details">
                        <div>
                            <div className="print-a5-label">Тип послуги</div>
                            <div className="print-a5-box-block">{props.printA5Info.type}</div>
                        </div>
                        <div className="double-box">
                            <div className="print-a5-label">Сторона, що сплачує послуги</div>
                            <div className="print-a5-box-block">{props.printA5Info.payer}</div>
                            <div className="print-a5-box-block">{props.printA5Info.paymentType}</div>
                        </div>
                        <div>
                            <div className="print-a5-label">Наложений платіж</div>
                            <div className="print-a5-box-block">{props.printA5Info.cashOnDelivery}</div>
                        </div>
                        <div>
                            <div className="print-a5-label">Вартість доставки</div>
                            <div className="print-a5-box-block">{props.printA5Info.costOfDelivery}</div>
                        </div>
                    </div>
                </div>
                <div className="print-a5-col-center">
                    <div className="print-a5-group">
                        <div className="print-a5-block-title">Відправник</div>
                        <div className="print-a5-row">
                            <div className="print-a5-col left-col">
                                <div className="print-a5-label">Місто відправник</div>
                                <div className="print-a5-box-block">{props.printA5Info.senderCity}</div>
                            </div>
                            <div className="print-a5-col right-col">
                                <div className="print-a5-label">Назва фірми</div>
                                <div className="print-a5-box-block">{props.printA5Info.sender}</div>
                            </div>
                        </div>
                        <div className="print-a5-row">
                            <div className="print-a5-col left-col">
                                <div className="print-a5-label">Телефон, Контактна особа</div>
                                <div className="print-a5-box-block">{props.printA5Info.sender}</div>
                                <div className="print-a5-box-block mt-small">{props.printA5Info.senderPhone}</div>
                            </div>
                            <div className="print-a5-col right-col">
                                <div className="print-a5-label">Адреса вантажу</div>
                                <div className="print-a5-box-block full-height">{props.printA5Info.senderAddress}</div>
                            </div>
                        </div>
                    </div>
                    <div className="print-a5-group">
                        <div className="print-a5-block-title">отримувач</div>
                        <div className="print-a5-row">
                            <div className="print-a5-col left-col">
                                <div className="print-a5-label">Місто отримувач</div>
                                <div className="print-a5-box-block">{props.printA5Info.deliveryCity}</div>
                            </div>
                            <div className="print-a5-col right-col">
                                <div className="print-a5-label">Назва фірми</div>
                                <div className="print-a5-box-block">{props.printA5Info.recipient}</div>
                            </div>
                        </div>
                        <div className="print-a5-row">
                            <div className="print-a5-col left-col">
                                <div className="print-a5-label">Телефон, Контактна особа</div>
                                <div className="print-a5-box-block">{props.printA5Info.recipient}</div>
                                <div className="print-a5-box-block mt-small">{props.printA5Info.phone}</div>
                            </div>
                            <div className="print-a5-col right-col">
                                <div className="print-a5-label">Адреса вантажу</div>
                                <div className="print-a5-box-block full-height">{props.printA5Info.address}</div>
                            </div>
                        </div>
                    </div>
                    <div className="print-a5-group">
                        <div className="print-a5-block-title">Представник отримувача</div>
                        <div className="print-a5-row receiver-data">
                            <div className="print-a5-col receiver-date">
                                <div className="print-a5-label">Дата</div>
                                <div
                                    className="print-a5-box-block">{props.printA5Info.representativeDate.split('T')[0] !== '0001-01-01' &&
                                    props.printA5Info.representativeDate.split('T')[0] || ''}
                                </div>
                            </div>
                            <div className="print-a5-col receiver-time">
                                <div className="print-a5-label">Час</div>
                                <div
                                    className="print-a5-box-block">{props.printA5Info.representativeDate.split('T')[1] !== '00:00:00' &&
                                    props.printA5Info.representativeDate.split('T')[1] || ''}
                                </div>
                            </div>
                            <div className="print-a5-col receiver-name-pos">
                                <div className="print-a5-label">ПІП, посада</div>
                                <div className="print-a5-box-block">
                                    {props.printA5Info.representativeName && props.printA5Info.representativePosition &&
                                        props.printA5Info.representativeName + ', ' + props.printA5Info.representativePosition || ''}
                                </div>
                            </div>
                            <div className="print-a5-col receiver-passport-data">
                                <div className="print-a5-label">Паспортні дані</div>
                                <div
                                    className="print-a5-box-block">{props.printA5Info.representativePassport || ''}</div>
                            </div>
                        </div>
                    </div>
                    <div className="print-a5-col-center-bottom">
                        <div className="print-a5-row-value">
                            <div className="print-a5-label">Документи супроводжуючі відправлення</div>
                            <div className="print-a5-box-block">{props.printA5Info.TEST || ''}</div>
                        </div>
                        <div className="print-a5-row-value">
                            <div className="print-a5-label">Повний опис відправлення</div>
                            <div className="print-a5-box-block">{props.printA5Info.accompanyingDescription}</div>
                        </div>
                        <div className="print-a5-row-value">
                            <div className="print-a5-label">До сплати отримувачем</div>
                            <div className="print-a5-box-block total-price">{props.printA5Info.TEST || ''}</div>
                        </div>
                        <div className="print-a5-row-agreement">
                            <div className="print-a5-row-agreement-text">
                                З умовами договру згоден ознайомлений заборонених
                                для пересилання речовин
                                і предметів грошей не містить.
                                Ці умови доступні для ознайомлення
                                на офіційному сайті
                            </div>
                            <div className="print-a5-row-agreement-sign">Підпис, печатка</div>
                        </div>
                    </div>
                </div>
                <div className="print-a5-col-right">
                    <div className="print-a5-about">
                        <div className="print-a5-block-title">Про відправлення</div>
                        <div className="print-a5-row">
                            <div className="print-a5-box-block w-80">Місць (шт)</div>
                            <div className="print-a5-box-block w-72">{props.printA5Info.parcelPlaces}</div>
                        </div>
                        <div className="print-a5-col print-a5-col-weight">
                            <div className="print-a5-label">Вага</div>
                            <div className="print-a5-row">
                                <div className="print-a5-box-block w-80">Фактична (кг)</div>
                                <div className="print-a5-box-block w-72">{props.printA5Info.weight}</div>
                            </div>
                            <div className="print-a5-row">
                                <div className="print-a5-box-block w-80 borderless">Обємна (м3)</div>
                                <div
                                    className="print-a5-box-block w-72 borderless">{props.printA5Info.volumeWeight}</div>
                            </div>
                            <div className="print-a5-row">
                                <div className="print-a5-box-block w-80 borderless">Контрольна</div>
                                <div
                                    className="print-a5-box-block w-72 borderless">{props.printA5Info.controlWeight}</div>
                            </div>
                        </div>
                        <div className="print-a5-col">
                            <div className="print-a5-label">Оголошена вартість</div>
                            <div className="print-a5-box-block">{props.printA5Info.price}</div>
                        </div>
                        <div className="print-a5-col print-a5-col-pack-type">
                            <div className="print-a5-label">Вид пакування</div>
                            <div className="print-a5-box-block">{props.printA5Info.packingType}</div>
                        </div>
                    </div>
                    <div className="print-a5-additional">
                        <div className="print-a5-block-title">Додаткові послуги</div>
                        <div className="print-a5-col">
                            <div className="print-a5-label">Супермаркет</div>
                            <div
                                className="print-a5-box-block">{props.printA5Info.supermarket ? i18next.t('Да') : i18next.t('Нет')}</div>
                        </div>
                        <div className="print-a5-col">
                            <div className="print-a5-label">Суботня доставка</div>
                            <div
                                className="print-a5-box-block">{props.printA5Info.saturday ? i18next.t('Да') : i18next.t('Нет')}</div>
                        </div>
                        <div className="print-a5-row">
                            <div className="print-a5-col w-95">
                                <div className="print-a5-label">Підйом на поверх</div>
                                <div
                                    className="print-a5-box-block">{props.printA5Info.floorDelivery ? i18next.t('Да') : i18next.t('Нет')}</div>
                            </div>
                            <div className="print-a5-col grow">
                                <div className="print-a5-label">Поверх</div>
                                <div className="print-a5-box-block">{props.printA5Info.floor || '-'}</div>
                            </div>
                        </div>
                        <div className="print-a5-row">
                            <div className="print-a5-col w-95">
                                <div className="print-a5-label">Повернення палет</div>
                                <div
                                    className="print-a5-box-block">{props.printA5Info.palletReturn ? i18next.t('Да') : i18next.t('Нет')}</div>
                            </div>
                            <div className="print-a5-col grow">
                                <div className="print-a5-label">Шт</div>
                                <div className="print-a5-box-block">{props.printA5Info.palletAmount || '-'}</div>
                            </div>
                        </div>
                        <div className="print-a5-col">
                            <div className="print-a5-label">Зворотня доставка док-тів</div>
                            <div
                                className="print-a5-box-block">{props.printA5Info.docsBack ? i18next.t('Да') : i18next.t('Нет')}</div>
                        </div>
                        <div className="print-a5-col">
                            <div className="print-a5-label">Зворотня доставка ЦП</div>
                            <div
                                className="print-a5-box-block">{props.printA5Info.cpBack ? i18next.t('Да') : i18next.t('Нет')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default withNamespaces()(StickerA5Main);
