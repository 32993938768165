import React, { useState } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/uk';

import { default as localeRu } from 'antd/es/date-picker/locale/ru_RU';
import { default as localeUk } from 'antd/es/date-picker/locale/uk_UA';

import Icon from "./Icon";

const { RangePicker } = DatePicker;
const dateFormat = 'DD.MM.YYYY';

const Datepicker = (props) => {
    const [dates, setDates] = useState([]);
    const [hackValue, setHackValue] = useState();
    const [value, setValue] = useState();

    const dateRangeChange = val => {
        let dates = [val[0]['_d'], val[1]['_d']];
        let newDates = [];

        for (let el of dates) {
            let date, month;
            el.getDate() < 10 ? date = `0${el.getDate()}` : date = el.getDate();
            el.getMonth() + 1 < 10 ? month = `0${el.getMonth() + 1}` : month = el.getMonth() + 1;
            let year = el.getFullYear();
            newDates.push(`${year}${month}${date}`);
        }

        dateFromChange(newDates[0]);
        dateToChange(newDates[1]);
        setValue(val);
    }

    const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return current && current > moment().endOf('day');
        }
        const tooLate = (dates[0] && current.diff(dates[0], 'days') > 31) || (current && current > moment().endOf('day'));
        const tooEarly = (dates[1] && dates[1].diff(current, 'days') > 31) || (current && current > moment().endOf('day'));
        return tooEarly || tooLate;
    };

    const onOpenChange = open => {
        if (open) {
            setHackValue([]);
            setDates([]);
        } else {
            setHackValue(undefined);
        }
    };

    function formatDate(date) {
        let d = date.slice(6, 8);
        let m = date.slice(4, 6);
        let y = date.slice(0, 4);
        return `${d}.${m}.${y}`;
    }

    function dateFromChange(date) {
        props.onDateFromChange(date);
    }

    function dateToChange(date) {
        props.onDateToChange(date);
    }

    return (
            <RangePicker
                value={hackValue || value}
                disabledDate={disabledDate}
                onCalendarChange={val => setDates(val)}
                onChange={dateRangeChange}
                onOpenChange={onOpenChange}
                locale={localStorage.getItem('lang') === 'ru' ? localeRu : localeUk}
                defaultValue={[moment(formatDate(props.dateFrom), dateFormat), moment(formatDate(props.dateTo), dateFormat)]}
                format={dateFormat}
                placeholder=''
                suffixIcon={<Icon src='/images/calendar-blue.svg' />}
            />
    );
};

export default Datepicker;