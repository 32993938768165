import React from "react";
import {Form, Input, Button, Row, Col, message} from 'antd';
import {Link} from "react-router-dom";
import { withNamespaces } from 'react-i18next'
import {GoogleLogin} from "react-google-login";
import FacebookAuth from "react-facebook-auth";
import Languages from "./Languages";
import Icon from "./Icon";

const Restore = ({t}) => {
    const onFinish = (values) => {
        fetch('https://highway.biz.ua/api/1c/restore', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(json => {
                if (json.error) {
                    console.log(json.error);
                    message.error(json.error);
                } else {
                    console.log(json);
                    message.success(t('На указанный адрес электронной почты отправлен временный пароль, введите его'));
                }
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const responseGoogle = (response) => {
        console.log(response);
        if (response.error) return false;

        const credentials = {
            "username": response.profileObj.email,
            "password": "loggedWithGoogle"
        }
        // onFinish(credentials);
    }

    const MyFacebookButton = ({ onClick }) => (
        <button onClick={onClick}>
            fb
        </button>
    );

    const authenticate = (response) => {
        console.log(response);
        // Api call to server so we can validate the token
    };

    return (
        <div className="bg">
            <Row>
                <Col span={14} className={'image-packs'}>

                </Col>
                <Col span={10} className={'df'}>
                    <Languages/>
                    <div className="inner-block">
                        <a className="logo" href="/">
                            <img src={t('/images/logo.svg')} alt=""/>
                        </a>
                        <h1>{t('Востановить пароль')}</h1>
                        <div className="heading-description">
                            {t('Введите электронную почту вашего аккаунта, вам будет выслана ссылка для востановления пароля')}
                        </div>
                        <Form
                            className={'login-input-block'}
                            name="basic"
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >

                            <Form.Item
                                name="usermail"
                                rules={[
                                    {
                                        required: true,
                                        message: t('Введите ваш e-mail'),
                                    },
                                ]}
                            >
                                <Input placeholder="E-mail" className={'login-input'} suffix={<Icon src='/images/mail.svg' />} />
                            </Form.Item>

                            <Form.Item
                                wrapperCol={{
                                    offset: 0,
                                    span: 24,
                                }}
                            >
                                <Button type="primary" htmlType="submit" className={'submit-button'}>
                                    {t('Продолжить')}
                                </Button>
                            </Form.Item>
                        </Form>
                        {/*<div className="under-form">*/}
                        {/*    {t('Или продолжите с этим социальным профилем')}*/}
                        {/*</div>*/}
                        {/*<div className="social-buttons">*/}
                        {/*    <Row>*/}
                        {/*        <Col>*/}
                        {/*            <GoogleLogin*/}
                        {/*                clientId="562332141456-lrqtudrd5hv7epvu951bb3vqkpr2hoca.apps.googleusercontent.com"*/}
                        {/*                buttonText=""*/}
                        {/*                onSuccess={responseGoogle}*/}
                        {/*                onFailure={responseGoogle}*/}
                        {/*                cookiePolicy={'single_host_origin'}*/}
                        {/*                className="google-login"*/}
                        {/*            />*/}
                        {/*            <Button className="social-btn">*/}
                        {/*                <img src="/images/google.svg" alt=""/>*/}
                        {/*            </Button>*/}
                        {/*        </Col>*/}
                        {/*        <Col>*/}
                        {/*            <div className="google-login">*/}
                        {/*                <FacebookAuth*/}
                        {/*                    appId="3077939085775742"*/}
                        {/*                    callback={authenticate}*/}
                        {/*                    component={MyFacebookButton}*/}
                        {/*                />*/}
                        {/*            </div>*/}
                        {/*            <Button className="social-btn">*/}
                        {/*                <img src="/images/facebook.svg" alt=""/>*/}
                        {/*            </Button>*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*</div>*/}
                        <div className="auth-links">
                            <Row>
                                <Col>
                                    <Link to="/login" className="register-link">
                                        {t('Вернуться на страницу входа')}
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>

    );
};

export default withNamespaces()(Restore);