import React, {useEffect, useState} from 'react';
import './App.css';
import './Adaptive.css';
import {
    BrowserRouter as Router,
    Switch,
    Route, Redirect, useHistory,
} from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import Dashboard from "./components/Dashboard";
import Restore from "./components/Restore";
import Settings from "./components/Settings";
import Statistics from "./components/Statistics";
import Notifications from "./components/Notifications";
import {withNamespaces} from 'react-i18next';
import {useCookies} from "react-cookie";

function App() {
    // const history = useHistory();
    const [cookies, setCookie] = useCookies([]);
    const [isAuth, setIsAuth] = useState(cookies.clientisauthed || false);
    // const [isTokenUpdated, setTokenUpdated] = useState(false);

    function isAuthChange(newIsAuth) {
        setCookie('clientisauthed', newIsAuth, { path: '/' });
        setIsAuth(newIsAuth);
    }

    const [messages, setMessages] = useState(0);

    function isMsgChange(newMsg) {
        setMessages(newMsg);
    }

    // useEffect(() => {
    //     const token = cookies.authtoken;
    //     if (token) {
    //         fetch('https://highway.biz.ua/api/1c/refreshAuthToken', {
    //             method: 'POST',
    //             body: JSON.stringify({'x-auth-token': token}),
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         })
    //             .then(res => res.json())
    //             .then(json => {
    //                 if (json) {
    //                     setCookie('authtoken', json.Token, { path: '/' });
    //                     setTokenUpdated(true);
    //                 }
    //             })
    //     } else {
    //         history.push('/login');
    //         // setTokenUpdated(true);
    //     }
    // }, [])

    return (
        <div className="App">
            {/*{isTokenUpdated && (*/}
                <Router>
                    <div>
                        <Switch>
                            <Route path="/login">
                                <Login onIsAuthChange={isAuthChange} logged={isAuth}/>
                            </Route>
                            <Route path="/register">
                                <Register logged={isAuth}/>
                            </Route>
                            <Route path="/restore">
                                <Restore logged={isAuth}/>
                            </Route>
                            <Route path="/dashboard">
                                {isAuth ? <Dashboard onIsAuthChange={isAuthChange} logged={isAuth} onMsgChange={isMsgChange}
                                                     messages={messages}/> : <Redirect to="/login"/>}
                            </Route>
                            <Route path="/settings">
                                {isAuth ? <Settings onIsAuthChange={isAuthChange} logged={isAuth} onMsgChange={isMsgChange}
                                                    messages={messages}/> : <Redirect to="/login"/>}
                            </Route>
                            <Route path="/notifications">
                                {isAuth ?
                                    <Notifications onIsAuthChange={isAuthChange} logged={isAuth} onMsgChange={isMsgChange}
                                                   messages={messages}/> : <Redirect to="/login"/>}
                            </Route>
                            <Route path="/statistics">
                                {isAuth ?
                                    <Statistics onIsAuthChange={isAuthChange} logged={isAuth} onMsgChange={isMsgChange}
                                                messages={messages}/> : <Redirect to="/login"/>}
                            </Route>
                        </Switch>
                    </div>
                </Router>
            {/*)}*/}
        </div>
    );
}

export default withNamespaces()(App);
