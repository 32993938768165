import React from "react";
import {Link} from "react-router-dom";
import Navigation from "./Navigation";
import {useCookies} from "react-cookie";

const Sidebar = () => {
    const [cookies] = useCookies([]);

    return (
        <aside className="admin-panel">
            <div className="admin-panel-info">
                <img src={cookies.clientava} alt="" className="user-avatar"/>
                <div>
                    <div className="user-name">{`${cookies.clientname} ${cookies.clientlastname[0]}.`}</div>
                    <div className="user-position">{cookies.clientposition}</div>
                </div>
                <Link to="/settings" className="user-settings-link">
                    <img src="/images/settings.svg" alt=""/>
                </Link>
            </div>
            <Navigation />
        </aside>
    );
};

export default Sidebar;