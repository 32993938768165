import React from "react";
import {Form, Input, Button, Row, Col, message} from 'antd';
import {Link, useHistory} from "react-router-dom";
import Icon from "./Icon";
import Languages from "./Languages";
import { GoogleLogin } from 'react-google-login';
import FacebookAuth from 'react-facebook-auth';
import { withNamespaces } from 'react-i18next';
import { useCookies } from 'react-cookie';
import {isTokenValid} from "../utils";
import i18next from 'i18next';

const Login = (props) => {
    const history = useHistory();
    const [form] = Form.useForm();

    const [cookies, setCookie] = useCookies([]);

    const error = (err) => {
        message.error(err);
    }

    const onFinish = (values) => {
        fetch('https://highway.biz.ua/api/1c/auth', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                if (json.error === 'Пожалуйста, закончите регистрацию') {
                    history.push('/register');
                }

                localStorage.getItem('lang') === 'ru' ? error(json.error) : error(json.error_uk);
            } else {
                console.log(json)
                setCookie('authtoken', json.Token, { path: '/' });
                setCookie('tokenexpires', json.TokenLifetime, { path: '/' });
                setCookie('clientid', json.ClientId, { path: '/' });
                setCookie('clientname', json.FirstName, { path: '/' });
                setCookie('clientlastname', json.LastName, { path: '/' });
                setCookie('clientmail', json.Email, { path: '/' });
                setCookie('clientphone', json.Phone, { path: '/' });
                setCookie('clientposition', json.Position, { path: '/' });
                setCookie('clientisadmin', json.Admin, { path: '/' });
                setCookie('clientdbid', json.Dbid, { path: '/' });
                setCookie('clientava', json.Avatar, { path: '/' });
                setCookie('clientisauthed', true, { path: '/' });
                setCookie('clientsawprinttip', json.SawPrintTip, { path: '/' });

                props.onIsAuthChange(true);

                history.push('/dashboard');
            }
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const responseGoogle = (response) => {
        console.log(response);
        if (response.error) return false;


        if (response.profileObj.givenName) {
            localStorage.setItem('g_name', response.profileObj.givenName);
        }
        if (response.profileObj.familyName) {
            localStorage.setItem('g_last_name', response.profileObj.familyName);
        }
        if (response.profileObj.email) {
            localStorage.setItem('g_mail', response.profileObj.email);
        }
        if (response.profileObj.imageUrl) {
            localStorage.setItem('g_avatar', response.profileObj.imageUrl);
        }

        const credentials = {
            "username": response.profileObj.email,
            "password": "loggedWithGoogle"
        }
        onFinish(credentials);
    }

    const MyFacebookButton = ({ onClick }) => (
        <button onClick={onClick}>
            fb
        </button>
    );

    const authenticate = (response) => {
        console.log(response);
        // Api call to server so we can validate the token
    };

    return (
        <div className="bg">
            <Row>
                <Col span={14} className={'image-packs'}>

                </Col>
                <Col span={10} className={'df'}>
                    <Languages/>
                    <div className="inner-block">
                        <a className="logo" href="/">
                            <img src={i18next.t('/images/logo.svg')} alt=""/>
                        </a>
                        <h1>{i18next.t('Добро пожаловать')}!</h1>
                        <div className="heading-description">
                            {i18next.t('С помощью учетной записи вы сможете отслеживать перемещение грузов и историю отправлений')}
                        </div>
                        <Form
                            form={form}
                            className={'login-input-block'}
                            name="basic"
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >

                            <Form.Item
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: i18next.t('Введите ваш e-mail'),
                                    },
                                ]}
                            >
                                <Input placeholder="E-mail" className='login-input' suffix={<Icon src='/images/user.svg' />} />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: i18next.t('Введите пароль'),
                                    },
                                ]}
                            >
                                <Input.Password placeholder="Пароль" className={'login-input'} />
                            </Form.Item>

                            <Form.Item
                                wrapperCol={{
                                    offset: 0,
                                    span: 24,
                                }}
                            >
                                <Button type="primary" htmlType="submit" className={'submit-button'}>
                                    {i18next.t('Войти')}
                                </Button>
                            </Form.Item>
                        </Form>
                        {/*<div className="under-form">*/}
                        {/*    {i18next.t('Или продолжите с этим социальным профилем')}*/}
                        {/*</div>*/}
                        {/*<div className="social-buttons">*/}
                        {/*    <Row>*/}
                        {/*        <Col>*/}
                        {/*            <GoogleLogin*/}
                        {/*                clientId="562332141456-lrqtudrd5hv7epvu951bb3vqkpr2hoca.apps.googleusercontent.com"*/}
                        {/*                buttonText=""*/}
                        {/*                onSuccess={responseGoogle}*/}
                        {/*                onFailure={responseGoogle}*/}
                        {/*                cookiePolicy={'single_host_origin'}*/}
                        {/*                className="google-login"*/}
                        {/*                icon={false}*/}
                        {/*            />*/}
                        {/*            <Button className="social-btn">*/}
                        {/*                <img src="/images/google.svg" alt=""/>*/}
                        {/*            </Button>*/}
                        {/*        </Col>*/}
                        {/*        <Col>*/}
                        {/*            <div className="google-login">*/}
                        {/*                <FacebookAuth*/}
                        {/*                    appId="3077939085775742"*/}
                        {/*                    callback={authenticate}*/}
                        {/*                    component={MyFacebookButton}*/}
                        {/*                />*/}
                        {/*            </div>*/}
                        {/*            <Button className="social-btn">*/}
                        {/*                <img src="/images/facebook.svg" alt=""/>*/}
                        {/*            </Button>*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*</div>*/}
                        <div className="auth-links">
                            <Row>
                                <Col>
                                    <Link to="/restore" className="forgot-pass">
                                        {i18next.t('Забыли пароль')}?
                                    </Link>
                                </Col>
                                <Col>
                                    <Link to="/register" className="register-link">
                                        {i18next.t('Зарегистрироваться')}
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>

    );
};

export default withNamespaces()(Login);