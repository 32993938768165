import React, {useState} from "react";
import {Row, Col} from 'antd';
import Header from "./Header";
import Sidebar from "./Sidebar";
import NotificationsUser from "./NotificationsUser";
import NotificationsAdmin from "./NotificationsAdmin";
import { useCookies } from 'react-cookie';

const Notifications = (props) => {
    const [cookies, setCookie] = useCookies([]);

    const renderNotificationsContent = () => {
        return cookies.clientisadmin === 'admin' ? <NotificationsAdmin/> : <NotificationsUser onMsgChange={props.onMsgChange} messages={props.messages} />;
    }

    return (
        <div className="notifications-page">
            <Header onIsAuthChange={props.onIsAuthChange} onMsgChange={props.onMsgChange} messages={props.messages} />
            <Row className="cabinet-content">
                <Col span={4} >
                    <Sidebar pageNum="1" />
                </Col>
                {renderNotificationsContent()}
            </Row>
        </div>
    );
};

export default Notifications;