import React, {useEffect, useRef, useState} from "react";
import {Row, Col, Upload, message, Button, Tooltip, Select} from 'antd';
import Header from "./Header";
import Sidebar from "./Sidebar";
import Datepicker from "./Datepicker";
import Tablerow from "./Tablerow";
import Tablehead from "./Tablehead";
import Tablepagination from "./Tablepagination";
import {getCurrentDate, isTokenValid} from "../utils";
import {getPreviousDate} from "../utils";
import Download from "./Download";
import Filter from "./Filter";
import { withNamespaces } from "react-i18next";
import { useCookies } from 'react-cookie';
import i18next from 'i18next';
import {useHistory} from "react-router-dom";
import Search from "antd/es/input/Search";
import useOutsideClick from "../hooks/useOutsideClick";

const Dashboard = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies([]);
    const history = useHistory();
    const { Option } = Select;

    if (!props.logged) {
        props.onIsAuthChange(false);
        removeCookie('authtoken');
        removeCookie('tokenexpires');
        removeCookie('clientid');
        removeCookie('clientname');
        removeCookie('clientlastname');
        removeCookie('clientmail');
        removeCookie('clientphone');
        removeCookie('clientposition');
        removeCookie('clientisadmin');
        removeCookie('clientdbid');
        removeCookie('clientava');
        removeCookie('clientunreadmsg');
        removeCookie('clientsawprinttip');
        setCookie('clientisauthed', false, { path: '/' });
        history.push('/login');
    }

    const initialFilter = {
        number: '',
        recipient: '',
        address: ''
    }

    const isDataFiltered = () => {
        return filter.address.length || filter.number.length || filter.recipient.length
    }

    const [parcels, setParcels] = useState([]);
    const [totalParcelsAmount, setParcelsAmount] = useState(0);
    const [index, setIndex] = useState(1);
    const [dateFrom, setDateFrom] = useState(getPreviousDate);
    const [dateTo, setDateTo] = useState(getCurrentDate);
    const [allParcelsList, setAllParcelsList] = useState([]);
    const [filter, setFilter] = useState({
        status: 'All',
        ...initialFilter
    });
    const [sortMode, setSortMode] = useState("0");
    const [searchType, setSearchType] = useState("number");
    const [searchValue, setSearchValue] = useState('');
    const [selectedType, setSelectedType] = useState('termo');
    const [searchPlaceholder, setSearchPlaceholder] = useState('');

    function indexChange(newIndex) {
        setIndex(newIndex);
    }

    function dateFromChange(newDateFrom) {
        setDateFrom(newDateFrom);
        setIndex(1);
    }

    function dateToChange(newDateTo) {
        setDateTo(newDateTo);
        setIndex(1);
    }

    function formatDate(date) {
        console.log(date)
        if (!date) return ''

        let d = date.slice(8, 10);
        let m = date.slice(5, 7);
        let y = date.slice(0, 4);
        return `${d}.${m}.${y}`;
    }

    function filterStatusChange(newFilter) {
        const updated = {...filter, status: newFilter};
        setFilter(updated);
        setIndex(1);
    }

    const handleSearchChange = (val) => {
        setSearchValue(val);
    }

    const onSearch = (val) => {
        const updated = {
            status: filter.status,
            ...initialFilter,
            [searchType]: val
        }

        setFilter(updated);
    }

    const handleSearchTypeChange = (value) => {
        setSearchType(value);
        setTypeSelectOpen(false);
    };

    useEffect(() => {
        let text = '';
        if (searchType === 'number') {
            text = i18next.t('Поиск декларации...');
        } else if (searchType === 'recipient') {
            text = i18next.t('Поиск получателя...');
        } else {
            text = i18next.t('Поиск адреса...');
        }

        setSearchPlaceholder(text);
    }, [searchType])

    function sortModeChange(newSortMode) {
        setSortMode(newSortMode);
        setIndex(1);
    }

    useEffect(() => {
        setSearchValue('');
    }, [searchType])

    useEffect(() => {
        fetch('https://highway.biz.ua/api/1c/getParcelsList', {
            method: 'POST',
            body: JSON.stringify({"DateFrom": dateFrom,
                "DateTo": dateTo,
                "Index": index,
                "PageSize": totalParcelsAmount,
                "ClientID": cookies.clientid,
                "SortMode": sortMode,
                "Filter": filter.status,
                "Filter_ParcelNumber": filter.number,
                "Filter_ParcelRecipient": filter.recipient,
                "Filter_ParcelRecipientAddress": filter.address,
                "x-auth-token": cookies.authtoken,
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.Status === 'Error' && data.ErrorMessage === 'Не корректный Токен') {
                    history.push('/login');
                }
                let result = [];

                if (data.ParcelList) {
                    for (let el of data.ParcelList) {
                        let obj = {};
                        obj.number = el['Номер'];
                        obj.deliveryDate = el['ДатаДоставки'].substring(0, 10);
                        obj.deliveryDateFact = el['ДатаДоставкиФакт'].substring(0, 10);
                        obj.sendingDate = el['ДатаПосылки'].substring(0, 10);
                        obj.adress = el['АдресДоставки'];
                        obj.status = el['СтатусПосылки'];
                        obj.receiver = el['ПолучательПосылки'];
                        obj.phone = el['ТелефонПолучателя'];
                        obj.weight = el['ВесПосылки'] + ' кг';
                        obj.deliveryCost = el['СтоимостьДоставкиСНДС'] + ' грн.';
                        obj.price = el['ОценочнаяСтоимость'] + ' грн.';
                        result.push(obj);
                    }
                }

                setAllParcelsList(result);
            })
    }, [totalParcelsAmount]);

    useEffect(() => {
        fetch('https://highway.biz.ua/api/1c/getParcelsList', {
            method: 'POST',
            body: JSON.stringify({"DateFrom": dateFrom,
                "DateTo": dateTo,
                "Index": index,
                "PageSize": "7",
                "ClientID": cookies.clientid,
                "SortMode": sortMode,
                "Filter": filter.status,
                "Filter_ParcelNumber": filter.number,
                "Filter_ParcelRecipient": filter.recipient,
                "Filter_ParcelRecipientAddress": filter.address,
                "x-auth-token": cookies.authtoken,
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.Status === 'Error' && data.ErrorMessage === 'Не корректный Токен') {
                    history.push('/login');
                }

                if (!data) {
                    setParcels([]);
                    setParcelsAmount(0);
                } else {
                    // console.log(data)
                    setParcels(data.ParcelList);
                    setParcelsAmount(data.TotalParcels);
                }
            })
    },[index, dateFrom, dateTo, filter, sortMode]);

    const statusSwitch = (status) => {
        switch(status) {
            case 'В работе':
                return 'handling';
            case 'Доставляется':
            case 'В пути':
                return 'in-process';
            case 'Доставлен':
                return 'finished';
        }
    }

    const uploadSettings = {
        name: 'file',
        action: 'https://highway.biz.ua/api/1c/sendUploadedFile',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        showUploadList: false,
    };

    const searchTypesList = [
        {text: i18next.t('По номеру посылки'), value: 'number'},
        {text: i18next.t('По имени получателя'), value: 'recipient'},
        {text: i18next.t('По адресу получателя'), value: 'address'}
    ]

    const [isTypeSelectOpen, setTypeSelectOpen] = useState(false);

    const selectRef = useRef(null);
    useOutsideClick(selectRef, () => {
        setTypeSelectOpen(false);
    });

    return (
        <div className="dashboard main">
            <Header onIsAuthChange={props.onIsAuthChange} onMsgChange={props.onMsgChange} messages={props.messages} />
            <Row className="cabinet-content">
                <Col span={4} >
                    <Sidebar />
                </Col>
                <Col className="dashboard-content" span={20}>
                    <div className="dashboard-content-top">
                        <h1>{i18next.t('История отправления')}</h1>
                        <div>
                            <Upload {...uploadSettings}>
                                <Tooltip placement="bottom" title={i18next.t("Отправка файла реестра менеджеру по работе с клиентами")} color="#76859C">
                                    <button className="dashboard-export-button upload">
                                        <img src="/images/upload.svg" alt=""/>
                                        <span>{i18next.t('Импортировать')}</span>
                                    </button>
                                </Tooltip>
                            </Upload>
                            <Download parcels={allParcelsList} />
                        </div>
                    </div>
                    <div className="dashboard-filter">
                        <div className={`${isTypeSelectOpen ? 'active' : ''} search-type`}>
                            <div className="caret" onClick={() => setTypeSelectOpen(prev => !prev)}>
                                <img src="/images/caret-down.svg" alt=""/>
                            </div>
                            {isTypeSelectOpen &&
                                <div className='search-type-list' ref={selectRef}>
                                    {searchTypesList.map(el =>
                                        <div
                                            className="search-type-option"
                                            onClick={() => handleSearchTypeChange(el.value)}
                                        >
                                            {el.text}
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                        <Search
                            placeholder={i18next.t(searchPlaceholder)}
                            allowClear
                            onSearch={onSearch}
                            value={searchValue}
                            onChange={e => handleSearchChange(e.target.value)}
                            className={`search-field ${isDataFiltered() ? 'active' : ''}`}
                        />
                        <div className="dashboard-filter-list">
                            <span className="dashboard-filter-title">{i18next.t('Фильтр')}:</span>
                            <Filter onFilterChange={filterStatusChange} />
                        </div>
                        <div id="result-amount">{`${totalParcelsAmount} ${i18next.t('результатов')}`}</div>
                        <Datepicker dateFrom={dateFrom} dateTo={dateTo} onDateFromChange={dateFromChange} onDateToChange={dateToChange} />
                    </div>
                    <Tablehead
                        onSortModeChange={sortModeChange}
                        currentMode={sortMode}
                        type={selectedType}
                        onTypeChange={setSelectedType}
                    />
                    <div className="dashboard-table-body">
                        {parcels && parcels.length ? (
                            parcels.map(parcel => (
                                <Tablerow
                                    key={parcel['Номер']}
                                    number={parcel['Номер']}
                                    date={formatDate(parcel['ДатаДоставки'])}
                                    // dateAlt={formatDate(parcel['АльтернативнаяДатаДоставки'])}
                                    dateFact={formatDate(parcel['ДатаДоставкиФакт'])}
                                    parcelDate={formatDate(parcel['ДатаПосылки'])}
                                    address={parcel['АдресДоставки'].split(',').join(', ')}
                                    recipient={parcel['ПолучательПосылки']}
                                    status={parcel['СтатусПосылки']}
                                    statusName={statusSwitch(parcel['СтатусПосылки'])}
                                    phone={parcel['ТелефонПолучателя']}
                                    weight={parcel['ВесПосылки']}
                                    costOfDelivery={parcel['СтоимостьДоставкиСНДС']}
                                    price={parcel['ОценочнаяСтоимость']}
                                    afiiliate={parcel['ФилиалПолучатель']}
                                    district={parcel['РайонДоставки']}
                                    sender={parcel['ОтправительПосылки']}
                                    senderAddress={parcel['АдресОтправки']}
                                    senderPhone={parcel['ТелефонОтправителя']}
                                    parcelPlaces={parcel['КоличествоТарныхМест']}
                                    volume={parcel['ОбъёмПосылки']}
                                    type={parcel['ТипУслуги']}
                                    payer={parcel['Плательщик']}
                                    paymentType={parcel['ВидОплаты']}
                                    additionalServices={parcel['ДопУслуги']}
                                    other={parcel['ДополнительнаяИнформация']}
                                    volumeWeight={parcel['ОбъемныйВесПосылки']}
                                    deliveryCity={parcel['ГородДоставкиПосылки']}
                                    // companyMistake={parcel['ОшибкаХВ']}
                                    controlWeight={parcel['КонтрольныйВес']}
                                    senderCity={parcel['ГородОтправкиПосылки']}
                                    transit={parcel['Магистраль_Транзит']}
                                    packingType={parcel['ТипУпаковки']}
                                    accompanyingDescription={parcel['ОписаниеСоправождающиеПосылку']}
                                    highwayRepresentative={parcel['ПредставительХВ']}
                                    cashOnDelivery={parcel['НаложенныйПлатеж']}
                                    printType={selectedType}
                                    supermarket={parcel['ДопУслугиСупермаркет']}
                                    saturday={parcel['ДопУслугиСубботняяДоставка']}
                                    floorDelivery={parcel['ДопУслугиПдъемНаЭтаж']}
                                    floor={parcel['ДопУслугиПдъемНаЭтаж_Этаж']}
                                    palletReturn={parcel['ДопУслугиОбратнаяДоставкаПаллет']}
                                    palletAmount={parcel['ДопУслугиОбратнаяДоставкаПаллет_Паллет']}
                                    docsBack={parcel['ДопУслугиОбратнаяДоставкаДокументов']}
                                    cpBack={parcel['ДопУслугиСентвичПанель']}
                                    representativeDate={parcel['ПредставительПолучателя_ДатаПоученияПосылки']}
                                    representativePosition={parcel['ПредставительПолучателя_ДолжностьПредставителя']}
                                    representativeName={parcel['ПредставительПолучателя_ПредставительПолучателя']}
                                    representativePassport={parcel['ПредставительПолучателя_ПаспортПредставителя']}
                                />
                            ))
                        ) : null}
                    </div>
                    <Tablepagination total={totalParcelsAmount} onChange={indexChange}  />
                </Col>
            </Row>
        </div>
    );
};

export default withNamespaces()(Dashboard);
