import React, {useState} from "react";
import Icon from "./Icon";
import { withNamespaces } from "react-i18next";
import i18next from 'i18next';

const Tablehead = (props) => {
    function parcelDateSortModeChange() {
        let mode;
        if (props.currentMode === "0") {
            mode = "1";
            document.querySelector('.head-delivery-date').classList.add('active');
        } else {
            mode = "0";
            document.querySelector('.head-delivery-date').classList.remove('active');
        }
        props.onSortModeChange(mode);
    }

    const handleTypeChange = (type) => {
        props.onTypeChange(type);
    }

    // function deliveryDateSortModeChange() {
    //     let mode;
    //     if (props.currentDeliveryMode === "0") {
    //         mode = "1";
    //         document.querySelector('.head-delivery-date').classList.add('active');
    //     } else {
    //         mode = "0";
    //         document.querySelector('.head-delivery-date').classList.remove('active');
    //     }
    //     props.onDeliverySortModeChange(mode);
    // }

    return (
        <div className="dashboard-table-head">
            <div className="head-number">
                №
            </div>
            <div className="head-delivery-date" onClick={parcelDateSortModeChange}>
                {i18next.t("Дата отправки")}
                <Icon src="/images/arrow-down.svg" />
            </div>
            <div className="head-delivery-date">
                {i18next.t("Дата доставки")}
                {/*<Icon src="/images/arrow-down.svg" />*/}
            </div>
            <div className="head-delivery-address">
                {i18next.t("Адрес доставки")}
            </div>
            <div className="head-recipient">
                {i18next.t("Получатель")}
            </div>
            <div className="head-status">
                {i18next.t("Статус отправления")}
            </div>
            <div className="print-type-switch">
                <div
                    className={props.type === 'termo' ? 'active' : ''}
                    onClick={() => handleTypeChange('termo')}
                >
                    100 x 100
                </div>
                <div
                    className={props.type === 'a5' ? 'active' : ''}
                    onClick={() => handleTypeChange('a5')}
                >
                    A5
                </div>
            </div>
        </div>
    );
};

export default withNamespaces()(Tablehead);
