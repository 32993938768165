import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import translationUK from './locales/uk/translation.json';

// the translations
const resources = {
    uk: {
        translation: translationUK
    }
};

i18n
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.getItem('lang') || 'ru',

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;