import React, { useEffect, useState } from "react";
import {Row, Col} from 'antd';
import Header from "./Header";
import Sidebar from "./Sidebar";
import Datepicker from "./Datepicker";
import StatsItem from "./StatsItem";
import {getCurrentDate} from "../utils";
import {getPreviousDate} from "../utils";
import { withNamespaces } from "react-i18next";
import {useHistory} from "react-router-dom";
import StatsChart from "./StatsChart";
import {useCookies} from "react-cookie";
import i18next from 'i18next';

const Statistics = (props) => {
    const history = useHistory();
    const [cookies] = useCookies([]);
    const [delayedParcels, setDelayedParcels] = useState([]);

    if (!cookies.authtoken || cookies.authtoken === 'undefined') {
        history.push('/login');
    }

    const [parcelsAmount, setParcelsAmount] = useState(0);
    const [ontimeParcelsAmount, setOntimeParcelsAmount] = useState(0);
    const [intimeParcelsAmount, setIntimeParcelsAmount] = useState(0);
    const [delayedParcelsAmount, setDelayedParcelsAmount] = useState(0);
    const [ontimeParcelsAmountPercentage, setOntimeParcelsAmountPercentage] = useState(0);
    const [intimeParcelsAmountPercentage, setIntimeParcelsAmountPercentage] = useState(0);
    const [dateFrom, setDateFrom] = useState(getPreviousDate);
    const [dateTo, setDateTo] = useState(getCurrentDate);
    const [isExecuted, setIsExecuted] = useState(false);

    function dateFromChange(newDateFrom) {
        setDateFrom(newDateFrom);
    }

    function dateToChange(newDateTo) {
        setDateTo(newDateTo);
    }

    // function getIntimeParcels(parcel) {
    //     let date = getDateOrDateAlt(parcel);
    //
    //     if (+date.substring(0, 4) > +parcel['ДатаДоставкиФакт'].substring(0, 4)) {
    //         return parcel;
    //     } else if (+date.substring(5, 7) > +parcel['ДатаДоставкиФакт'].substring(5, 7)) {
    //         return parcel;
    //     } else if (+date.substring(8, 10) > +parcel['ДатаДоставкиФакт'].substring(8, 10)) {
    //         return parcel;
    //     } else {
    //         return null;
    //     }
    // }

    // const getDateOrDateAlt = parcel => {
    //     if (parcel['АльтернативнаяДатаДоставки'] === '0001-01-01T00:00:00') {
    //         return parcel['ДатаДоставки'];
    //     }
    //
    //     return parcel['ОшибкаХВ'] ? parcel['ДатаДоставки'] : parcel['АльтернативнаяДатаДоставки'];
    // }

    const sortParcelsByDates = (parcels) => {
        let intime = [];
        let ontime = [];
        let delayed = [];

        parcels.forEach(el => {
            // let date = getDateOrDateAlt(el);
            let date = el['ДатаДоставки'];
            let dateFact = el['ДатаДоставкиФакт'];

            if (date.substring(0, 10) === dateFact.substring(0, 10)) {
                ontime.push(el);
                return;
            }

            if (+dateFact.substring(0, 4) > +date.substring(0, 4)) {
                delayed.push(el);
                return;
            } else if ( +dateFact.substring(5, 7) > +date.substring(5, 7) && +dateFact.substring(0, 4) > +date.substring(0, 4) ) {
                delayed.push(el);
                return;
            } else if ( +dateFact.substring(8, 10) > +date.substring(8, 10) && +dateFact.substring(5, 7) === +date.substring(5, 7) ) {
                delayed.push(el);
                return;
            }

            if (+dateFact.substring(0, 4) < +date.substring(0, 4)) {
                intime.push(el);
                ontime.push(el);
            } else if (+dateFact.substring(5, 7) < +date.substring(5, 7)) {
                intime.push(el);
                ontime.push(el);
            } else if (+dateFact.substring(8, 10) < +date.substring(8, 10)) {
                intime.push(el);
                ontime.push(el);
            }
        })

        setDelayedParcels(delayed)
        // console.log(delayed)

        // console.group(ontime, delayed)

        setOntimeParcelsAmount(ontime.length);
        setIntimeParcelsAmount(intime.length);
        setDelayedParcelsAmount(delayed.length);

        setIntimeParcelsAmountPercentage(Math.floor(+intime.length / +parcels.length * 100));
        setOntimeParcelsAmountPercentage(Math.floor(+ontime.length / +parcels.length * 100));
    }

    useEffect(() => {
        fetch('https://highway.biz.ua/api/1c/getParcelsList', {
            method: 'POST',
            body: JSON.stringify({
                "DateFrom": dateFrom,
                "DateTo": dateTo,
                "Index": 1,
                "PageSize": "1",
                "ClientID": cookies.clientid,
                "SortMode": "0",
                "Filter": "Delivered",
                "x-auth-token": cookies.authtoken,
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.Status === 'Error' && data.ErrorMessage === 'Не корректный Токен') {
                    history.push('/login');
                }

                if (data != null) {
                    setParcelsAmount(data.TotalParcels);
                }
                return data.TotalParcels
            })
            .then(t => {
                fetch('https://highway.biz.ua/api/1c/getParcelsList', {
                    method: 'POST',
                    body: JSON.stringify({
                        "DateFrom": dateFrom,
                        "DateTo": dateTo,
                        "Index": 1,
                        "PageSize": t,
                        "ClientID": cookies.clientid,
                        "SortMode": "0",
                        "Filter": "Delivered",
                        "x-auth-token": cookies.authtoken,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                    .then(resp => resp.json())
                    .then(result => {
                        if (result.Status === 'Error' && result.ErrorMessage === 'Не корректный Токен') {
                            history.push('/login');
                        }

                        if (result.ParcelList.length > 0) {
                            sortParcelsByDates(result.ParcelList);
                        }
                        setIsExecuted(true);
                    })
            })
        },[dateFrom, dateTo]);

    if (isExecuted) {
        return (
            <div className="dashboard">
                <Header onIsAuthChange={props.onIsAuthChange} onMsgChange={props.onMsgChange} messages={props.messages} />
                <Row className="cabinet-content">
                    <Col span={4}>
                        <Sidebar/>
                    </Col>
                    <Col className="dashboard-content" span={20}>
                        <div className="dashboard-content-top">
                            <h1>{i18next.t('Статистика отправлений')}</h1>
                            <Datepicker dateFrom={dateFrom} dateTo={dateTo} onDateFromChange={dateFromChange}
                                        onDateToChange={dateToChange}/>
                        </div>
                        <div className="stats-page-content-wrapper">
                            <div className="stats-page-content-charts">
                                <div className="stats-items-list">
                                    <StatsItem text={i18next.t('Всего отправок за период:')} number={parcelsAmount} color="#182B44" className={i18next.t('stat-item-ru')}/>
                                    <StatsItem text={i18next.t('Своевременная доставка:')} number={ontimeParcelsAmount} color="#0072d4"/>
                                    <StatsItem text={i18next.t('Доставка с опозданием:')} number={delayedParcelsAmount} color="#f9760c"/>
                                    <StatsItem text={i18next.t('Доставка раньше срока:')} number={intimeParcelsAmount} color="#1caf00"/>
                                </div>
                                <div className="stats-charts-list">
                                    <StatsChart percent={ontimeParcelsAmountPercentage}
                                                text={i18next.t('Своевременная доставка по адресу, %')} color="rgb(0, 114, 212)"/>
                                    <StatsChart percent={intimeParcelsAmountPercentage} text={i18next.t('Доставка раньше срока, %')}
                                                color="#1caf00"/>
                                </div>
                            </div>
                            <div className="competitors-stats-wrapper">
                                <div className="competitors-stats-item">
                                    <div className="competitors-stats-item-heading">Статистика</div>
                                    <a href="https://novaposhta.ua/ru/pokaznyky_kompanii"
                                       className="competitors-stats-item-link" target="_blank">Нова Пошта</a>
                                </div>
                                <div className="competitors-stats-item">
                                    <div className="competitors-stats-item-heading">Статистика</div>
                                    <a href="https://www.delivery-auto.com/uk-UA/Kpi/Indexes"
                                       className="competitors-stats-item-link" target="_blank">Delivery</a>
                                </div>
                            </div>
                        </div>
                        {cookies.clientisadmin === 'admin' && delayedParcels.map(el =>
                            <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                <div>Номер: {el["Номер"]}</div>
                                <div>Отправка: {el["ДатаПосылки"]}</div>
                                <div>Доставка план: {el["ДатаДоставки"]}</div>
                                <div>Доставка факт: {el["ДатаДоставкиФакт"]}</div>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        );
    } else {
        return (
            <div className="dashboard">
                <Header onIsAuthChange={props.onIsAuthChange} onMsgChange={props.onMsgChange} messages={props.messages} />
                <Row className="cabinet-content">
                    <Col span={4}>
                        <Sidebar/>
                    </Col>
                    <Col className="dashboard-content" span={20}>
                        <div className="dashboard-content-top">
                            <h1>{i18next.t('Статистика отправлений')}</h1>
                            <Datepicker dateFrom={dateFrom} dateTo={dateTo} onDateFromChange={dateFromChange}
                                        onDateToChange={dateToChange}/>
                        </div>
                        <div className="stats-page-content-wrapper">
                            <div className="stats-page-content-charts loading">
                                <div className="loader" />
                            </div>
                            <div className="competitors-stats-wrapper">
                                <div className="competitors-stats-item">
                                    <div className="competitors-stats-item-heading">Статистика</div>
                                    <a href="https://novaposhta.ua/ru/pokaznyky_kompanii"
                                       className="competitors-stats-item-link" target="_blank">Нова Пошта</a>
                                </div>
                                <div className="competitors-stats-item">
                                    <div className="competitors-stats-item-heading">Статистика</div>
                                    <a href="https://www.delivery-auto.com/uk-UA/Kpi/Indexes"
                                       className="competitors-stats-item-link" target="_blank">Delivery</a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
};

export default withNamespaces()(Statistics);
