import React, { useState } from 'react';
import { withNamespaces } from "react-i18next";
import i18next from 'i18next';
import Barcode from 'react-barcode';

const StickerMain = React.forwardRef((props, ref) => {
    const [ratio, setRatio] = useState(window.innerWidth / 1920);

    window.addEventListener('resize', function() {
        setRatio(window.innerWidth / 1920);
    })

    return (
        <div className="print-table-wrapper" ref={ref}>
            <div className="sticker-main-content">
                <div className="print-table-row">
                    <div className="sixth">{props.printInfo['parcelDate']}</div>
                    <div className="third text-lg">{props.printInfo['number']}</div>
                    <div className="sixth">{props.printInfo['district']}</div>
                </div>
                <div className="print-table-row region text-lg">{props.printInfo['afiiliate']}</div>
                <div className="print-table-row">
                    <div className="third text-big">{props.printInfo['parcelPlaces']} місць</div>
                    <div className="third text-big">{props.printInfo['weight']} кг</div>
                    <div className="third text-big">{props.printInfo['volume']} м³</div>
                </div>
                <div className="print-table-row">
                    <div className="half text-md">ВІДПРАВНИК</div>
                    <div className="half text-md">ОТРИМУВАЧ</div>
                </div>
                <div className="print-table-row">
                    <div className="half">{props.printInfo['sender']}</div>
                    <div className="half">{props.printInfo['recipient']}</div>
                </div>
                <div className="print-table-row">
                    <div className="half">{props.printInfo['senderAddress']}</div>
                    <div className="half">{props.printInfo['address']}</div>
                </div>
                <div className="print-table-row">
                    <div className="half">{props.printInfo['senderPhone']}</div>
                    <div className="half">{props.printInfo['phone']}</div>
                </div>
                <div className="print-table-row">
                    <div className="half">{props.printInfo['type']}</div>
                    <div className="half">{props.printInfo['additionalServices']}</div>
                </div>
                <div className="print-table-row no-bd">
                    <div className="print-table-row half no-bd">
                        <div className="print-table-col half">
                            <div>{props.printInfo['payer']}</div>
                            <div>{props.printInfo['paymentType'].slice(0, 8)}.</div>
                        </div>
                        <div className="half">{props.printInfo['costOfDelivery']} грн</div>
                    </div>
                    <div className="half">{props.printInfo['other']}</div>
                </div>
            </div>
            <div className="print-table-col barcode-vertical">
                <Barcode value={props.printInfo['number'] + '/1'} width={props.barWidth ? props.barWidth : 2} height={props.barHeight ? props.barHeight : 70} font="Montserrat" displayValue={false} />
            </div>
        </div>
    );
});

export default withNamespaces()(StickerMain);