import React from 'react';
import StickerMain from './StickerMain';
import StickerA5Main from './StickerA5Main';
import StickerSmall from './StickerSmall';

const PrintCardFocus = (props) => {
    return (
        <div className="modal-print-focus-sticker">
            {
                props.printCardNumber === 1
                    ? props.printType === 'a5'
                        ? <StickerA5Main printInfo={props.printInfo} printA5Info={props.printA5Info} />
                        : <StickerMain printInfo={props.printInfo} />
                    : <StickerSmall printInfo={props.printInfo} printCardNumber={props.printCardNumber} />
            }
        </div>
    );
};

export default PrintCardFocus;
